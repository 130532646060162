import React, {
    useCallback,
    useContext,
    useEffect,
    useMemo,
    useReducer,
} from "react";
import { withRouter } from "react-router-dom";
import { toast } from "react-toastify";
import { useKeycloak } from "@react-keycloak/web";
import {
    AccessPermissionModuleNames,
    AccessPermissionModules,
    UserBoundaryType,
} from "Data";
import { KeycloakContext } from "./keycloakAuthServiceContext";
import {
    getModules,
    getOrganizationDetails,
    getProfile,
    getRegions,
    getUserDataset,
    postUserDataset,
} from "Services";
import { getValueFromObject, setFavicon, setSiteTitle } from "Utils";

const UserContext = React.createContext();

const UserContextActions = {
    UPDATE_USER_DATA: "updateUserData",
    SET_ORGANIZATION: "setOrganization",
    SET_IS_LOADING_ORGANIZATION: "setIsLoadingOrganization",
    SET_INIT_SYSTEM_ERROR_MESSAGE: "setInitSystemErrorMsg",
    SELECT_REGION: "setSelectRegion",
    SET_USER_STATE: "setUserState",
    SET_USER_CONFIG_LOADED: "setUserConfigLoaded",
    SET_SYSTEM_MODULES: "setSystemModules",
    SET_IS_SYSTEM_MODULE: "setIsSystemModule",
    SET_REGIONS: "setRegions",
    SET_LOGGED_USER: "setLoggedUser",
};

// * Restructured for ease access.
const inititalUserPermissionPolicies = (enabledAll = true) =>
    Object.values(AccessPermissionModules).reduce(
        (result, { moduleName, actions }) => {
            result[moduleName] = Object.values(actions).reduce((r, item) => {
                r[item] = enabledAll;
                return r;
            }, {});
            return result;
        },
        {}
    );

const initialState = {
    userBoundaryType: UserBoundaryType.ROOT,
    boundaryMerchantId: "",
    regionId: "",
    selectedRegion: { providerConfiguration: [] }, // * Assumption: if user is a regional/merchant bounded, selected region will be set to that region
    organization: {
        organizationName: "",
        organizationLogoImageUrl: "",
        address: { street: "", city: "", zip: "" },
        regions: [],
        configuration: { providerConfiguration: [] },
    },
    isRunningSystemInit: true,
    systemInitFailMessage: null,
    config: {
        memberTableColumns: [
            "name",
            "contact",
            "points",
            "tier",
            "createdOn",
            "lastSeenOn",
        ],
    },
    userConfigLoaded: false,
    systemModules: [],
    isLoadingModules: true,
    userPermissions: [],
    loggedUser: null,
    userProfileLoadCompleted: false,
    userRegions: [],
    isLoadingOrganization: false,
};

const reducer = (state, action) => {
    switch (action.type) {
        case UserContextActions.UPDATE_USER_DATA: {
            const { boundary, ...rest } = action.data;
            return {
                ...state,
                ...rest,
                userBoundaryType: boundary,
                userProfileLoadCompleted: true,
            };
        }
        case UserContextActions.SET_IS_LOADING_ORGANIZATION: {
            return {
                ...state,
                isLoadingOrganization: action.status,
            };
        }
        case UserContextActions.SET_ORGANIZATION: {
            return {
                ...state,
                organization: action.organization,
                isRunningSystemInit: false,
                systemInitFailMessage: null,
            };
        }
        case UserContextActions.SET_INIT_SYSTEM_ERROR_MESSAGE: {
            return {
                ...state,
                systemInitFailMessage: action.message,
                isRunningSystemInit: false,
            };
        }
        case UserContextActions.SET_USER_CONFIG_LOADED: {
            return {
                ...state,
                userConfigLoaded: action.status,
            };
        }
        case UserContextActions.SELECT_REGION: {
            return {
                ...state,
                regionId: action.regionId,
                selectedRegion: state.organization.regions?.find(
                    (item) => item._id === action.regionId
                ),
            };
        }
        case UserContextActions.SET_USER_STATE: {
            const { key, value, ...rest } = action;
            return {
                ...state,
                config: {
                    ...state.config,
                    [key]: value,
                },
                ...rest,
            };
        }
        case UserContextActions.SET_SYSTEM_MODULES: {
            return {
                ...state,
                systemModules: action.modules,
                isLoadingModules: false,
            };
        }
        case UserContextActions.SET_IS_SYSTEM_MODULE: {
            return {
                ...state,
                isLoadingModules: action.status,
            };
        }
        case UserContextActions.SET_REGIONS: {
            return {
                ...state,
                userRegions: action.regions,
                regionId: state?.organization?.configuration?.baseRegionId
                    ? state?.organization?.configuration?.baseRegionId
                    : action.regions[0]._id,
                selectedRegion: state?.organization?.configuration?.baseRegionId
                    ? action.regions.find(
                        (item) =>
                            item._id ===
                            state?.organization?.configuration?.baseRegionId
                    )
                    : action.regions[0],
            };
        }
        case UserContextActions.SET_LOGGED_USER: {
            return {
                ...state,
                loggedUser: action.userInfo,
            };
        }
        default:
            return state;
    }
};

const constantMock = window.fetch;

const UserContextProvider = withRouter((props) => {
    const {
        keycloakLogout: logout,
        login: keycloakLogin,
        authComplete,
        loadUserProfile,
    } = useContext(KeycloakContext);
    const [state, dispatch] = useReducer(reducer, initialState);
    const { keycloak, initialized } = useKeycloak();
    const isAuth = keycloak.authenticated || false;

    const userPermissionPolicies = useMemo(() => {
        let policies = inititalUserPermissionPolicies(
            state.userBoundaryType === UserBoundaryType.ROOT
        );
        if (state.userPermissions) {
            state.userPermissions.forEach((permissionItem = []) => {
                permissionItem.permissions?.forEach(({ group }) => {
                    group?.policies?.forEach((policy) => {
                        if (policies[policy.moduleName]) {
                            policy.actions?.forEach((action) => {
                                policies[policy.moduleName][action] = true;
                            });
                        }
                    });
                });
            });
        }

        return policies;
    }, [state.userPermissions, state.userBoundaryType]);

    window.fetch = async function () {
        const body = arguments[1];
        if (body.headers["x-auth"]) {
            delete body.headers["x-auth"];
            body.headers["Authorization"] = `Bearer ${keycloak.token}`;
        }

        if (body.headers["x-auth-scope"]) {
            if (!isAuthorizedForAction(body.headers["x-auth-scope"])) {
                toast.error(
                    `You are not authorized to perform the action: ${body.headers["x-auth-scope"]}`
                );
                return Promise.reject({ status: 403 });
            }
            delete body.headers["x-auth-scope"];
        }

        const response = await constantMock.apply(this, arguments);

        if (response.status === 401) {
            keycloak.logout();
        }

        return response;
    };

    const loadProfile = useCallback(async () => {
        try {
            const profileResponse = await getProfile();
            dispatch({
                type: UserContextActions.UPDATE_USER_DATA,
                data: profileResponse,
            });
        } catch (e) {
            console.error(e);
        }
    }, [dispatch]);

    const isAuthorizedForAction = useCallback(
        (moduleName, actionName) => {
            if (actionName)
                return userPermissionPolicies[moduleName][actionName];

            return (
                getValueFromObject(userPermissionPolicies, moduleName) || false
            );
        },
        [userPermissionPolicies]
    );

    let userStateUpdateTimeout;
    const onUserStateChange = useCallback(
        ({ key, value }) => {
            if (
                isAuthorizedForAction(
                    AccessPermissionModuleNames.USER_DATASET,
                    AccessPermissionModules[
                        AccessPermissionModuleNames.USER_DATASET
                    ].actions.CreateDataset
                )
            ) {
                dispatch({
                    type: UserContextActions.SET_USER_STATE,
                    key,
                    value: value,
                    userConfigLoaded: true,
                });
                if (userStateUpdateTimeout) {
                    clearTimeout(userStateUpdateTimeout);
                }
                // eslint-disable-next-line
                userStateUpdateTimeout = setTimeout(async () => {
                    await postUserDataset({
                        projection: value,
                        datasetKey: key,
                    });
                }, 3000);
            }
        },
        [dispatch]
    );

    const updateColumnState = useCallback(async () => {
        if (
            isAuthorizedForAction(
                AccessPermissionModuleNames.USER_DATASET,
                AccessPermissionModules[
                    AccessPermissionModuleNames.USER_DATASET
                ].actions.ListDatasets
            )
        ) {
            try {
                const columnState = await getUserDataset({
                    datasetKey: "memberTableColumns",
                });
                if (columnState?.data && columnState.data.length > 0) {
                    const columnTableState = columnState.data.find(
                        (item) => item.datasetKey === "memberTableColumns"
                    );
                    if (columnTableState) {
                        dispatch({
                            type: UserContextActions.SET_USER_STATE,
                            key: "memberTableColumns",
                            value: JSON.parse(columnTableState.datasetValue),
                            userConfigLoaded: true,
                        });
                    }
                } else {
                    dispatch({
                        type: UserContextActions.SET_USER_CONFIG_LOADED,
                        status: true,
                    });
                }
            } catch (e) {
                console.error(e);
            }
        } else {
            dispatch({
                type: UserContextActions.SET_USER_CONFIG_LOADED,
                status: true,
            });
        }
    }, [dispatch, isAuthorizedForAction]);

    const loadOrganization = useCallback(async () => {
        if (
            isAuthorizedForAction(
                AccessPermissionModuleNames.ORGANIZATION,
                AccessPermissionModules[
                    AccessPermissionModuleNames.ORGANIZATION
                ].actions.GetOrganization
            )
        ) {
            try {
                dispatch({
                    type: UserContextActions.SET_IS_LOADING_ORGANIZATION,
                    status: true,
                });
                const organizationResponse = await getOrganizationDetails();
                dispatch({
                    type: UserContextActions.SET_ORGANIZATION,
                    organization: organizationResponse,
                });

                if (organizationResponse.organizationFavicon) {
                    setFavicon(organizationResponse.organizationFavicon);
                }
                if (organizationResponse.organizationAppTitle) {
                    setSiteTitle(organizationResponse.organizationAppTitle);
                }
            } catch (e) {
                dispatch({
                    type: UserContextActions.SET_INIT_SYSTEM_ERROR_MESSAGE,
                    message:
                        "Could not load your organization details. Please try again. If the issue persists, contact support.",
                });
            } finally {
                dispatch({
                    type: UserContextActions.SET_IS_LOADING_ORGANIZATION,
                    status: false,
                });
            }
        } else {
            dispatch({
                type: UserContextActions.SET_INIT_SYSTEM_ERROR_MESSAGE,
                message:
                    "You do not have get organization details permission. Dashboard cannot load without this permission",
            });
        }
    }, [dispatch, isAuthorizedForAction]);

    const onSelectRegion = useCallback(
        (regionId) => {
            window.sessionStorage.setItem("selectRegion", regionId);
            dispatch({
                type: UserContextActions.SELECT_REGION,
                regionId: regionId,
            });
        },
        [dispatch]
    );

    const loadModules = useCallback(async () => {
        if (
            isAuthorizedForAction(
                AccessPermissionModuleNames.MODULES,
                AccessPermissionModules[AccessPermissionModuleNames.MODULES]
                    .actions.ListModules
            )
        ) {
            try {
                const modules = await getModules();
                dispatch({
                    type: UserContextActions.SET_SYSTEM_MODULES,
                    modules: modules.items || [],
                });
            } catch (e) {
                dispatch({
                    type: UserContextActions.SET_IS_SYSTEM_MODULE,
                    status: false,
                });
            }
        } else {
            dispatch({
                type: UserContextActions.SET_IS_SYSTEM_MODULE,
                status: false,
            });
        }
    }, [dispatch, isAuthorizedForAction]);

    const loadRegions = useCallback(async () => {
        if (
            isAuthorizedForAction(
                AccessPermissionModuleNames.REGION,
                AccessPermissionModules[AccessPermissionModuleNames.REGION]
                    .actions.ListRegions
            )
        ) {
            try {
                const regionResponse = await getRegions({
                    limit: 500,
                    skip: 0,
                });
                dispatch({
                    type: UserContextActions.SET_REGIONS,
                    regions: regionResponse.items || [],
                });
            } catch (e) {
                console.error(e);
                toast.error(
                    <div>
                        Failed to load regions!
                        <br />
                        {e.message
                            ? `Error: ${e.message}`
                            : "Please try again later."}
                    </div>
                );
            }
        }
    }, [isAuthorizedForAction, dispatch]);

    const loadUserInfo = useCallback(async () => {
        try {
            const userInfo = await loadUserProfile();
            dispatch({ type: UserContextActions.SET_LOGGED_USER, userInfo });
        } catch (e) {
            console.error(e);
            toast.error(
                <div>
                    Failed to load user profile!
                    <br />
                    {e.message
                        ? `Error: ${e.message}`
                        : "Please try again later."}
                </div>
            );
        }
    }, [loadUserProfile, dispatch]);

    useEffect(() => {
        (async () => {
            if (authComplete) {
                await loadProfile();
                loadOrganization();
                loadUserInfo();
            }
        })();
        // eslint-disable-next-line
    }, [authComplete]);

    useEffect(() => {
        (async () => {
            if (
                (authComplete &&
                    state.userPermissions &&
                    state.userPermissions.length !== 0) ||
                (authComplete &&
                    state.userBoundaryType === UserBoundaryType.ROOT)
            ) {
                loadModules();
                updateColumnState();
                loadRegions();
            }
        })();
        // eslint-disable-next-line
    }, [
        authComplete,
        state.userPermissions,
        state?.organization?.configuration?.baseRegionId,
    ]);

    useEffect(() => {
        if (window.sessionStorage.getItem("selectRegion")) {
            if (state.organization.regions.length !== 0) {
                onSelectRegion(window.sessionStorage.getItem("selectRegion"));
            }
        }
        // eslint-disable-next-line
    }, [state.userRegions]);

    const value = {
        ...state,
        isAuth,
        initialized,
        username: state.loggedUser?.username,
        email: state.loggedUser?.email,
        login: keycloakLogin,
        logout,
        loadProfile,
        onSelectRegion,
        onUserStateChange,
        loadOrganization,
        loadRegions,
        updateColumnState,
        isAuthorizedForAction,
    };

    return (
        <UserContext.Provider value={value}>
            {props.children}
        </UserContext.Provider>
    );
});

const UserContextConsumer = UserContext.Consumer;

export {
    UserContext,
    UserContextProvider,
    UserContextConsumer,
    UserContextActions,
};
