import React from "react";
import PropTypes from "prop-types";
import { FormSelect } from "@shoutout-labs/shoutout-themes-enterprise";
import { MonthsOptions } from "Utils";

const MonthPicker = ({ selected, disabled, onChange }) => {
    return (
        <FormSelect
            options={MonthsOptions}
            placeholder="Select a month"
            multiple={false}
            selected={selected}
            clearButton
            disabled={disabled}
            onChange={onChange}
            required
        />
    );
};

MonthPicker.defaultProps = {
    selected: [],
    disabled: false,
    onChange: () => {},
};

MonthPicker.propTypes = {
    selected: PropTypes.array,
    disabled: PropTypes.bool,
    onChange: PropTypes.func,
};

export default MonthPicker;
