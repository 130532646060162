import React from "react";
import PropTypes from "prop-types";
import {
    Button,
    Form,
    IcIcon,
} from "@shoutout-labs/shoutout-themes-enterprise";
import { faAngleDown, faAngleUp } from "FaICIconMap";

const TimeInput = ({
    name,
    value,
    min,
    max,
    step,
    incDecButtonValue,
    disabled,
    buttonsBySide,
    onChange,
    onIncrement,
    onDecrement,
}) => {
    return (
        <div className="d-flex align-items-center">
            <Form.Control
                className="time-picker-input text-center"
                type="number"
                size="lg"
                min={min}
                max={max}
                step={step}
                name={name}
                value={value}
                disabled={disabled}
                onChange={onChange}
                required
            />
            <div
                className={`ml-2 ${
                    buttonsBySide ? "d-flex align-items-center" : ""
                }`}
            >
                <Button
                    className={`timer-selector p-0 ${
                        buttonsBySide ? "mr-1" : ""
                    }`}
                    variant="primary"
                    size="sm"
                    name={`${name}-increment`}
                    data-increment-value={incDecButtonValue}
                    disabled={disabled || Number(value) >= Number(max)}
                    onClick={onIncrement}
                >
                    <IcIcon size="lg" icon={faAngleUp} />
                </Button>
                <Button
                    className="timer-selector p-0"
                    variant="primary"
                    size="sm"
                    name={`${name}-decrement`}
                    data-decrement-value={incDecButtonValue}
                    disabled={disabled || Number(value) <= Number(min)}
                    onClick={onDecrement}
                >
                    <IcIcon size="lg" icon={faAngleDown} />
                </Button>
            </div>
        </div>
    );
};

TimeInput.defaultProps = {
    value: "",
    max: "",
    min: "",
    step: "1",
    incDecButtonValue: "",
    disabled: false,
    buttonsBySide: false,
    onChange: () => {},
    onIncrement: () => {},
    onDecrement: () => {},
};

TimeInput.propTypes = {
    name: PropTypes.string.isRequired,
    value: PropTypes.string,
    min: PropTypes.string,
    max: PropTypes.string,
    step: PropTypes.string,
    incDecButtonValue: PropTypes.string,
    disabled: PropTypes.bool,
    buttonsBySide: PropTypes.bool,
    onChange: PropTypes.func,
    onIncrement: PropTypes.func,
    onDecrement: PropTypes.func,
};

export default TimeInput;
