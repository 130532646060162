import {
    faCalendar,
    faCheckCircle,
    faClock,
    faCreditCard,
    faExclamationCircle,
    faLayerGroup,
    faMapMarkerAlt,
    faMedal,
    faPower,
} from "FaICIconMap";
import { toTitleCase } from "Utils";

const RewardsHeaderTabs = {
    REWARD_POOL: "REWARD_POOL",
    REWARD_LOGISTICS: "REWARD_LOGISTICS",
};

const RewardType = {
    TANGIBLE: "TANGIBLE",
    DIGITAL: "DIGITAL",
};

const RewardSubType = {
    VOUCHER: "VOUCHER",
    PARTNER: "PARTNER",
    INSTANT_CARD: "INSTANT_CARD",
};

const RewardStatus = {
    DRAFT: "DRAFT",
    ENABLED: "ENABLED",
    DISABLED: "DISABLED",
};

const RewardPointValueType = {
    STATIC: "STATIC",
    BUNDLE: "BUNDLE",
};

const ValidityPeriod = {
    FIXED: "FIXED",
    OPEN: "OPEN",
};

const RewardValidity = {
    ACTIVE: "ACTIVE",
    SCHEDULED: "SHCEDULED",
    EXPIRED: "EXPIRED",
    NO_EXPIRY: "NO_EXPIRY",
};

const PortalVisibility = {
    PARTNER_AND_CUSTOMER: "PARTNER_AND_CUSTOMER",
    PARTNER_ONLY: "PARTNER_ONLY",
    CUSTOMER_ONLY: "CUSTOMER_ONLY",
    NONE: "NONE",
};

const DailyRedemptionLimit = {
    UNLIMITED: "UNLIMITED",
    LIMITED: "LIMITED",
};

const TopUpMethod = {
    GENERATED: {
        methodValue: "GENERATED",
        label: "AUTO_GENERATE",
    },
    UPLOAD: {
        methodValue: "UPLOAD",
        label: "UPLOAD_FILE",
    },
    MANUAL: {
        methodValue: "MANUAL",
        label: "PASTE_CODE",
    },
};

const TopUpStatus = {
    SUCCESS: "SUCCESS",
    FAILED: "FAILED",
};

const RewardPickUp = {
    ALL_LOCATIONS: "ALL_LOCATIONS",
    SELECTED_LOCATIONS: "SELECTED_LOCATIONS",
};

const RewardGenerationJobStatus = {
    PENDING: "PENDING",
    PROCESSING: "PROCESSING",
    DISPATCHED: "DISPATCHED",
    COMPLETED: "COMPLETED",
    FAILED: "FAILED",
};

const RewardGenerationJobGroups = {
    INDIVIDUAL: "INDIVIDUAL",
    BATCHES: "BATCHES",
};

const RedemptionStatus = {
    REQUESTED: "REQUESTED",
    READY: "READY",
    CLAIMED: "CLAIMED",
    CANCELLED: "CANCELLED",
    PROCESSING: "PROCESSING",
    COMPLETED: "COMPLETED",
    FAILED: "FAILED",
    REFUNDED: "REFUNDED",
};

const RewardRedemptionProcessStatus = {
    DISPATCH: "DISPATCH",
    COMPLETE: "COMPLETE",
    FAIL: "FAIL",
};

const RefundStatus = {
    NONE: "NONE",
    REFUNDED: "REFUNDED",
};

const RewardTabValues = {
    LOCKED: "LOCKED",
    REDEEMED: "REDEEMED",
    UNLOCKED: "UNLOCKED",
};

const RedeemedRewardsSubTabValues = {
    ALL: "ALL",
    PROCESSING_COMPLETED: "PROCESSING_COMPLETED",
};

const RewardSubTypeColorCode = {
    [RewardSubType.INSTANT_CARD]: {
        color: "white",
        backgroundColor: "#04a71a",
    },
    [RewardSubType.VOUCHER]: { color: "white", backgroundColor: "#08c59c" },
    [RewardSubType.PARTNER]: { color: "white", backgroundColor: "#5c5ab4" },
};

const PortalVisibilityColorCode = {
    [PortalVisibility.PARTNER_AND_CUSTOMER]: "success",
    [PortalVisibility.PARTNER_ONLY]: "info",
    [PortalVisibility.CUSTOMER_ONLY]: "secondary",
    [PortalVisibility.NONE]: "dark",
};

const PortalVisibilityTextColorCode = {
    [PortalVisibility.PARTNER_AND_CUSTOMER]: "text-success",
    [PortalVisibility.PARTNER_ONLY]: "text-info",
    [PortalVisibility.CUSTOMER_ONLY]: "text-secondary",
    [PortalVisibility.NONE]: "text-dark",
};

const RewardStatusColorCode = {
    [RewardStatus.DRAFT]: "dark",
    [RewardStatus.ENABLED]: "success",
    [RewardStatus.DISABLED]: "danger",
};

const RewardStatusTextColorCode = {
    [RewardStatus.DRAFT]: "text-dark",
    [RewardStatus.ENABLED]: "text-success",
    [RewardStatus.DISABLED]: "text-danger",
};

const RedemptionLogStatusColorCode = {
    [RedemptionStatus.REQUESTED]: {
        color: "black",
        backgroundColor: "#ffd15e",
    },
    [RedemptionStatus.READY]: { color: "white", backgroundColor: "#48d463" },
    [RedemptionStatus.CLAIMED]: { color: "white", backgroundColor: "#007bff" },
    [RedemptionStatus.CANCELLED]: {
        color: "white",
        backgroundColor: "#f25c5c",
    },
};

const TopUpMethodColorCode = {
    [TopUpMethod.GENERATED.methodValue]: "info",
    [TopUpMethod.UPLOAD.methodValue]: "warning",
    [TopUpMethod.MANUAL.methodValue]: "primary",
};

const TopUpStatusColorCode = {
    [TopUpStatus.SUCCESS]: "success",
    [TopUpStatus.FAILED]: "danger",
};

const RewardGenerationJobStatusColorCode = {
    [RewardGenerationJobStatus.PENDING]: {
        color: "black",
        backgroundColor: "#ffb907",
    },
    [RewardGenerationJobStatus.PROCESSING]: {
        color: "white",
        backgroundColor: "#fd7e14",
    },
    [RewardGenerationJobStatus.DISPATCHED]: {
        color: "white",
        backgroundColor: "#00a3ee",
    },
    [RewardGenerationJobStatus.COMPLETED]: {
        color: "white",
        backgroundColor: "#21b73f",
    },
    [RewardGenerationJobStatus.FAILED]: {
        color: "white",
        backgroundColor: "#d82e3c",
    },
};

const RewardRedemptionProcessStatusButtonColorCode = {
    [RewardRedemptionProcessStatus.DISPATCH]: {
        NORMAL: "secondary",
        OUTLINE: "outline-secondary",
    },
    [RewardRedemptionProcessStatus.COMPLETE]: {
        NORMAL: "success",
        OUTLINE: "outline-success",
    },
    [RewardRedemptionProcessStatus.FAIL]: {
        NORMAL: "danger",
        OUTLINE: "outline-danger",
    },
};

const RewardValidityTextColorCode = {
    [RewardValidity.ACTIVE]: "text-success",
    [RewardValidity.SCHEDULED]: "text-orange",
    [RewardValidity.EXPIRED]: "text-danger",
    [RewardValidity.NO_EXPIRY]: "text-secondary",
};

const RewardValidityInfo = {
    [RewardValidity.ACTIVE]: {
        validityText: RewardValidity.ACTIVE,
        validityIcon: faCheckCircle,
        validityTextColor: "text-success",
    },
    [RewardValidity.SCHEDULED]: {
        validityText: RewardValidity.SCHEDULED,
        validityIcon: faClock,
        validityTextColor: "text-orange",
    },
    [RewardValidity.EXPIRED]: {
        validityText: RewardValidity.EXPIRED,
        validityIcon: faExclamationCircle,
        validityTextColor: "text-danger",
    },
    [RewardValidity.NO_EXPIRY]: {
        validityText: RewardValidity.ACTIVE,
        validityIcon: faCheckCircle,
        validityTextColor: "text-success",
        rewardType: RewardValidity.NO_EXPIRY,
        rewardTypeTextColor: "text-secondary",
    },
};

const DefaultColumnsPerGroup = {
    INDIVIDUAL: [
        { name: "transaction_id", icon: faCreditCard, sort: false },
        { name: "reward_name", icon: faMedal, sort: false },
        { name: "pick_up_location", icon: faMapMarkerAlt, sort: false },
        { name: "date", icon: faCalendar, sort: false },
        { name: "status", icon: faPower, sort: false },
        { name: "_id", hidden: true, icon: null, sort: false },
    ],
    BATCHES: [
        { name: "batch_id", icon: faLayerGroup, sort: false },
        {
            dataField: "no_rewards",
            name: "no._rewards",
            icon: faMedal,
            sort: false,
        },
        { name: "date", icon: faCalendar, sort: false },
        { name: "status", icon: faPower, sort: false },
        { name: "_id", hidden: true, icon: null, sort: false },
        { name: "history_events", hidden: true, icon: null, sort: false },
    ],
};

const DefaultColumsObject = {
    PENDING: DefaultColumnsPerGroup.INDIVIDUAL,
    PROCESSING: {
        INDIVIDUAL: DefaultColumnsPerGroup.INDIVIDUAL,
        BATCHES: DefaultColumnsPerGroup.BATCHES,
    },
    DISPATCHED: {
        INDIVIDUAL: DefaultColumnsPerGroup.INDIVIDUAL,
        BATCHES: DefaultColumnsPerGroup.BATCHES,
    },
    COMPLETED: {
        INDIVIDUAL: DefaultColumnsPerGroup.INDIVIDUAL,
        BATCHES: DefaultColumnsPerGroup.BATCHES,
    },
    FAILED: {
        INDIVIDUAL: DefaultColumnsPerGroup.INDIVIDUAL,
        BATCHES: DefaultColumnsPerGroup.BATCHES,
    },
};

const RewardLogisticsFilter = [
    {
        value: "REWARD_NAME",
        name: "Reward Name",
    },
    {
        value: "LOCATION",
        name: "Location",
    },
    {
        value: "DATE",
        name: "Date",
    },
];

const TopUpMethodOptions = Object.entries(TopUpMethod).map(([key, value]) => ({
    label: toTitleCase(value.label),
    value: key,
}));

const TopUpStatusOptions = Object.keys(TopUpStatus).map((tUS) => ({
    label: toTitleCase(tUS),
    value: tUS,
}));

export {
    RewardsHeaderTabs,
    RewardType,
    RewardSubType,
    RewardStatus,
    ValidityPeriod,
    PortalVisibility,
    DailyRedemptionLimit,
    TopUpMethod,
    TopUpStatus,
    RewardPickUp,
    RedemptionLogStatusColorCode,
    TopUpMethodColorCode,
    TopUpStatusColorCode,
    RewardGenerationJobStatus,
    RewardGenerationJobGroups,
    RedemptionStatus,
    RewardRedemptionProcessStatus,
    RewardSubTypeColorCode,
    PortalVisibilityColorCode,
    PortalVisibilityTextColorCode,
    RewardStatusColorCode,
    RewardStatusTextColorCode,
    RewardGenerationJobStatusColorCode,
    DefaultColumsObject,
    RewardLogisticsFilter,
    RewardValidity,
    RewardValidityTextColorCode,
    RewardValidityInfo,
    RefundStatus,
    RewardPointValueType,
    RewardRedemptionProcessStatusButtonColorCode,
    RewardTabValues,
    RedeemedRewardsSubTabValues,
    TopUpMethodOptions,
    TopUpStatusOptions,
};
