import {
    fetchGet,
    fetchPost,
    jsonToQueryParam,
    preProcessBodyData,
} from "./CommonServiceUtils";
import Constants from "../Constants";
import { AccessPermissionModules, AccessPermissionModuleNames } from "Data";
import { getPermissionPath } from "Utils";

const BASE_URL = `${Constants.BASE_URL}loyaltyservice/klip/`;

const findMembersKlip = (queryObj) => {
    return fetchGet(
        `${BASE_URL}members?${jsonToQueryParam(queryObj)}`,
        true,
        getPermissionPath(
            AccessPermissionModuleNames.KLIP,
            AccessPermissionModules[AccessPermissionModuleNames.KLIP].actions
                .ListMembers
        )
    );
};

const countMembersKlip = (queryObj) => {
    return fetchGet(
        `${BASE_URL}members/count?${jsonToQueryParam(queryObj)}`,
        true,
        getPermissionPath(
            AccessPermissionModuleNames.KLIP,
            AccessPermissionModules[AccessPermissionModuleNames.KLIP].actions
                .ListMembers
        )
    );
};
const getContactByIdKlip = (contactId) => {
    return fetchGet(
        `${BASE_URL}members/${contactId}`,
        true,
        getPermissionPath(
            AccessPermissionModuleNames.KLIP,
            AccessPermissionModules[AccessPermissionModuleNames.KLIP].actions
                .GetMember
        )
    );
};

const getRedemptionLogsKlip = (queryObj) => {
    //Query params :
    // limit,
    // skip,
    // loyaltyId,
    // rewardId,
    // distributionJobId,
    // rewardSubType,
    // fromDate,
    // toDate,
    // searchKey - Not there in API schema
    return fetchGet(
        `${BASE_URL}redemptionlogs?${jsonToQueryParam(queryObj)}`,
        true,
        getPermissionPath(
            AccessPermissionModuleNames.KLIP,
            AccessPermissionModules[AccessPermissionModuleNames.KLIP].actions
                .ListRewardRedemptionLogs
        )
    );
};

const getRewardsKlip = (queryObj) => {
    //Query params : limit, skip, status, type, portalVisibility, searchKey
    return fetchGet(
        `${BASE_URL}rewards?${jsonToQueryParam(queryObj)}`,
        true,
        getPermissionPath(
            AccessPermissionModuleNames.KLIP,
            AccessPermissionModules[AccessPermissionModuleNames.KLIP].actions
                .ListRewards
        )
    );
};

const collectPointsManualBillKlip = (payload) => {
    return fetchPost(
        BASE_URL + "points/collect/bill",
        preProcessBodyData(payload),
        true,
        getPermissionPath(
            AccessPermissionModuleNames.KLIP,
            AccessPermissionModules[AccessPermissionModuleNames.KLIP].actions
                .CollectPointsBill
        )
    );
};

const collectPointsKlip = (payload) => {
    return fetchPost(
        BASE_URL + "points/collect/points",
        preProcessBodyData(payload),
        true,
        getPermissionPath(
            AccessPermissionModuleNames.KLIP,
            AccessPermissionModules[AccessPermissionModuleNames.KLIP].actions
                .CollectPointsAmount
        )
    );
};

const redeemRewardKlip = (payload) => {
    return fetchPost(
        `${BASE_URL}rewardredeem`,
        payload,
        true,
        getPermissionPath(
            AccessPermissionModuleNames.KLIP,
            AccessPermissionModules[AccessPermissionModuleNames.KLIP].actions
                .RedeemReward
        )
    );
};

const redeemPointsWithOtpRequestKlip = (payload) => {
    return fetchPost(
        BASE_URL + "points/redeemwithotprequest",
        payload,
        true,
        getPermissionPath(
            AccessPermissionModuleNames.KLIP,
            AccessPermissionModules[AccessPermissionModuleNames.KLIP].actions
                .RedeemPoints
        )
    );
};

const redeemPointsWithOtpKlip = (payload) => {
    return fetchPost(
        BASE_URL + "points/redeemwithotp",
        payload,
        true,
        getPermissionPath(
            AccessPermissionModuleNames.KLIP,
            AccessPermissionModules[AccessPermissionModuleNames.KLIP].actions
                .RedeemPoints
        )
    );
};

const redeemPointsWithoutOtpKlip = (payload) => {
    return fetchPost(
        BASE_URL + "points/redeem",
        payload,
        true,
        getPermissionPath(
            AccessPermissionModuleNames.KLIP,
            AccessPermissionModules[AccessPermissionModuleNames.KLIP].actions
                .RedeemPointsWithoutOtp
        )
    );
};

const adjustPointKlip = (payload) => {
    return fetchPost(
        BASE_URL + "points/adjust",
        payload,
        true,
        getPermissionPath(
            AccessPermissionModuleNames.KLIP,
            AccessPermissionModules[AccessPermissionModuleNames.KLIP].actions
                .AdjustPoints
        )
    );
};

export {
    findMembersKlip,
    getRewardsKlip,
    getRedemptionLogsKlip,
    getContactByIdKlip,
    collectPointsKlip,
    collectPointsManualBillKlip,
    redeemRewardKlip,
    redeemPointsWithOtpRequestKlip,
    redeemPointsWithOtpKlip,
    adjustPointKlip,
    redeemPointsWithoutOtpKlip,
    countMembersKlip
};
