const UserTypes = {
    USER: "USER",
    CLIENT: "CLIENT",
};

const UserStatus = {
    ACTIVE: "ACTIVE",
    SUSPENDED: "SUSPENDED",
    ARCHIVED: "ARCHIVED",
};

const UserStatusColorCode = {
    [UserStatus.ACTIVE]: "success",
    [UserStatus.SUSPENDED]: "orange",
    [UserStatus.ARCHIVED]: "danger",
};

const UserStatusOppositePresentTense = {
    [UserStatus.SUSPENDED]: "REACTIVATE",
    [UserStatus.ACTIVE]: "SUSPEND",
};

const UserStatusOppositeColorCode = {
    [UserStatus.SUSPENDED]: "outline-success",
    [UserStatus.ACTIVE]: "outline-orange",
};

export {
    UserTypes,
    UserStatus,
    UserStatusColorCode,
    UserStatusOppositePresentTense,
    UserStatusOppositeColorCode,
};
