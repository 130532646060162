import {
    faCalendar,
    faCreditCard,
    faHome,
    faUsers,
    faUser,
    faUserPlus,
    faGift,
    faUserCheck,
} from "FaICIconMap";
import { toTitleCase } from "Utils";

const PointRuleTypes = {
    TRANSACTIONAL: "TRANSACTIONAL",
    NON_TRANSACTIONAL: "NON_TRANSACTIONAL",
};

const PointRuleSubTypes = {
    GENERAL: {
        name: "General Spending Rule",
        value: "GENERAL",
        icon: faCreditCard,
    },
    LOCATION: {
        name: "Location Based Spending Rule",
        value: "LOCATION",
        icon: faHome,
    },
    SEASONAL: {
        name: "Seasonal Based Spending Rule",
        value: "SEASONAL",
        icon: faCalendar,
    },
    AFFINITY: {
        name: "Affinity Group Spending Rule",
        value: "AFFINITY",
        icon: faUsers,
    },
    ENROLL: {
        name: "Member Enroll",
        value: "ENROLL",
        icon: faUser,
    },
    SIGNUP: {
        name: "Member Signup",
        value: "SIGNUP",
        icon: faUserPlus,
    },
    PROFILE_COMPLETION: {
        name: "Profile Completion",
        value: "PROFILE_COMPLETION",
        icon: faUserCheck,
    },
    BIRTHDAY: {
        name: "Member Birthday",
        value: "BIRTHDAY",
        icon: faGift,
    },
};

const PointRuleStatus = {
    ENABLED: "ENABLED",
    DISABLED: "DISABLED",
    ARCHIVED: "ARCHIVED",
};

const PointRuleStates = {
    ACTIVE: "ACTIVE",
    EXPIRED: "EXPIRED",
    SCHEDULED: "SCHEDULED",
};

const AffinityGroupOptions = {
    ENTIER: "ENTIER",
    TIERS: "TIERS",
};

const Occasions = {
    NONE: "NONE",
    FOREVER: "FOREVER",
    PERIOD: "PERIOD",
};

const LocationSeasonalSubTypes = [
    PointRuleSubTypes.LOCATION.value,
    PointRuleSubTypes.SEASONAL.value,
];

const OccasionWithDates = [Occasions.NONE, Occasions.PERIOD];

const OccasionWithDays = [Occasions.FOREVER, Occasions.PERIOD];

const PointRuleStatusColorCode = {
    [PointRuleStatus.ENABLED]: "success",
    [PointRuleStatus.DISABLED]: "warning",
    [PointRuleStatus.ARCHIVED]: "danger",
};

const PointRuleStatusOptions = Object.keys(PointRuleStatus)
    .filter((pRSO) => pRSO !== PointRuleStatus.ARCHIVED)
    .map((fPRSO) => ({
        label: toTitleCase(fPRSO),
        value: fPRSO,
    }));

export {
    PointRuleTypes,
    PointRuleSubTypes,
    PointRuleStatus,
    PointRuleStates,
    AffinityGroupOptions,
    Occasions,
    LocationSeasonalSubTypes,
    OccasionWithDates,
    OccasionWithDays,
    PointRuleStatusColorCode,
    PointRuleStatusOptions,
};
