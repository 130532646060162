const ServiceTypes = {
    LOYALTY: "LOYALTY",
    IDENTITY: "IDENTITY",
    DATA_SYNC: "DATA_SYNC",
    MESSAGE: "MESSAGE",
};

const AuditOperation = {
    CREATE: "CREATE",
    READ: "READ",
    UPDATE: "UPDATE",
    DELETE: "DELETE",
};

const ServiceTypesColorCode = {
    [ServiceTypes.LOYALTY]: "purple",
    [ServiceTypes.IDENTITY]: "dark",
    [ServiceTypes.DATA_SYNC]: "orange",
    [ServiceTypes.MESSAGE]: "secondary",
};

const AuditOperationColorCode = {
    [AuditOperation.CREATE]: "success",
    [AuditOperation.READ]: "primary",
    [AuditOperation.UPDATE]: "warning",
    [AuditOperation.DELETE]: "danger",
};

export {
    ServiceTypes,
    ServiceTypesColorCode,
    AuditOperation,
    AuditOperationColorCode,
};
