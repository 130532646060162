const CardGenerateJobStatus = {
    PENDING: "PENDING",
    PRINTING: "PRINTING",
    PRINTED: "PRINTED",
    DISPATCHED: "DISPATCHED",
    NEW_REQUEST: "REQUESTED",
    COMPLETED: "COMPLETED",
    FAILED: "FAILED",
};

const CardGenerateJobStatusVariant = {
    [CardGenerateJobStatus.NEW_REQUEST]: "warning",
    [CardGenerateJobStatus.PENDING]: "warning",
    [CardGenerateJobStatus.PRINTING]: "orange",
    [CardGenerateJobStatus.PRINTED]: "info",
    [CardGenerateJobStatus.DISPATCHED]: "secondary",
    [CardGenerateJobStatus.COMPLETED]: "success",
    [CardGenerateJobStatus.FAILED]: "danger",
};

export { CardGenerateJobStatus, CardGenerateJobStatusVariant };
