const PermissionTypes = {
    READ: "READ",
    WRITE: "WRITE",
    DELETE: "DELETE",
};

const PermissionTypeColorMap = {
    [PermissionTypes.READ]: "primary",
    [PermissionTypes.WRITE]: "success",
    [PermissionTypes.DELETE]: "danger",
};

export { PermissionTypes, PermissionTypeColorMap };
