import {
    fetchGet,
    //fetchPut,
    fetchPost,
    fetchPut,
    //fetchDelete,
    jsonToQueryParam,
} from "./CommonServiceUtils";
import Constants from "../Constants";
import { AccessPermissionModules, AccessPermissionModuleNames } from "Data";
import { getPermissionPath } from "Utils";
const BASE_URL = `${Constants.BASE_URL}loyaltyservice/`;

const createCardBatchJob = ({
    jobType,
    regionId,
    merchantId,
    merchantLocationId,
    quantity,
    embossCardIds,
}) => {
    return fetchPost(
        `${BASE_URL}cardbatchjobs`,
        {
            jobType,
            regionId,
            merchantId,
            merchantLocationId,
            quantity,
            embossCardIds,
        },
        true,
        getPermissionPath(
            AccessPermissionModuleNames.CARD,
            AccessPermissionModules[AccessPermissionModuleNames.CARD].actions
                .CreateCardBatchJob
        )
    );
};

const createCardDistributionJobs = ({
    jobType,
    regionId,
    merchantId,
    merchantLocationId,
    quantity,
}) => {
    return fetchPost(
        `${BASE_URL}carddistributionjobs`,
        {
            jobType,
            regionId,
            merchantId,
            merchantLocationId,
            quantity,
        },
        true,
        getPermissionPath(
            AccessPermissionModuleNames.CARD,
            AccessPermissionModules[AccessPermissionModuleNames.CARD].actions
                .CreateCardBatchJob
        )
    );
};

const createCardPrintJobs = ({
    jobType,
    regionId,
    quantity,
    embossCardIds,
}) => {
    return fetchPost(
        `${BASE_URL}cardprintjobs`,
        {
            jobType,
            regionId,
            quantity,
            embossCardIds,
        },
        true,
        getPermissionPath(
            AccessPermissionModuleNames.CARD,
            AccessPermissionModules[AccessPermissionModuleNames.CARD].actions
                .CreateCardBatchJob
        )
    );
};

const getCardBatchJobs = (JobURLParam, queryObj) => {
    // Query params : limit, skip, createdOnFrom, createdOnTo, status, batchId, regionId, merchantId, merchantLocationId,
    return fetchGet(
        `${BASE_URL}${JobURLParam}?${jsonToQueryParam(queryObj)}`,
        true,
        getPermissionPath(
            AccessPermissionModuleNames.CARD,
            AccessPermissionModules[AccessPermissionModuleNames.CARD].actions
                .ListCardBatchJobs
        )
    );
};

const updateCardBatchJobs = (JobURLParam, id, payload) => {
    return fetchPut(
        `${BASE_URL}${JobURLParam}/${id}`,
        payload,
        true,
        getPermissionPath(
            AccessPermissionModuleNames.CARD,
            AccessPermissionModules[AccessPermissionModuleNames.CARD].actions
                .UpdateCardBatchJob
        )
    );
};

const getCardList = (queryObj) => {
    // Query params : limit, skip, assignedDateFrom, assignedDateTo, createdOnFrom, createdOnTo, status, batchJobId, loyaltyId, cardNo
    return fetchGet(
        `${BASE_URL}cards?${jsonToQueryParam(queryObj)}`,
        true,
        getPermissionPath(
            AccessPermissionModuleNames.CARD,
            AccessPermissionModules[AccessPermissionModuleNames.CARD].actions
                .ListCards
        )
    );
};

const exportCardList = (JobURLParam, queryObj) => {
    // Query params : batchJobId
    return fetchGet(
        `${BASE_URL}${JobURLParam}?${jsonToQueryParam(queryObj)}`,
        true,
        getPermissionPath(
            AccessPermissionModuleNames.CARD,
            AccessPermissionModules[AccessPermissionModuleNames.CARD].actions
                .ExportCardBatchJobs
        )
    );
};

const exportFailedEmbossedCardList = (JobURLParam, queryObj) => {
    // Query params : batchJobId
    return fetchGet(
        `${BASE_URL}${JobURLParam}?${jsonToQueryParam(queryObj)}`,
        true,
        getPermissionPath(
            AccessPermissionModuleNames.CARD,
            AccessPermissionModules[AccessPermissionModuleNames.CARD].actions
                .ListCards
        )
    );
};

const requestEmbossedCardForMember = (
    memberId,
    { printedName, merchantLocationId, address }
) => {
    return fetchPut(
        `${BASE_URL}cards/requestembossed/${memberId}`,
        {
            printedName,
            merchantLocationId,
            address,
        },
        true,
        getPermissionPath(
            AccessPermissionModuleNames.CARD,
            AccessPermissionModules[AccessPermissionModuleNames.CARD].actions
                .CreateEmbossedRequest
        )
    );
};

const assignCardToALoyaltyId = (queryObj) => {
    return fetchPost(
        `${BASE_URL}cards/assign`,
        queryObj,
        true,
        getPermissionPath(
            AccessPermissionModuleNames.CARD,
            AccessPermissionModules[AccessPermissionModuleNames.CARD].actions
                .AssignCard
        )
    );
};

const updateCardStatus = (id, payload) => {
    return fetchPut(
        `${BASE_URL}cards/${id}`,
        payload,
        true,
        getPermissionPath(
            AccessPermissionModuleNames.CARD,
            AccessPermissionModules[AccessPermissionModuleNames.CARD].actions
                .UpdateCard
        )
    );
};

const getCardSummary = ({ regionId, cardTypes }) => {
    return fetchGet(
        `${BASE_URL}cards/summary?${jsonToQueryParam({
            regionId,
            cardTypes: cardTypes,
        })}`,
        true,
        getPermissionPath(
            AccessPermissionModuleNames.CARD,
            AccessPermissionModules[AccessPermissionModuleNames.CARD].actions
                .ListCards
        )
    );
};

const getCardStock = (newHeader) => {
    return fetchGet(
        `${BASE_URL}cards/stock?${jsonToQueryParam(newHeader)}`,
        true,

        getPermissionPath(
            AccessPermissionModuleNames.CARD,
            AccessPermissionModules[AccessPermissionModuleNames.CARD].actions
                .ListCards
        )
    );
};

const getCardByCardNumber = (cardNo, regionId) =>
    getCardList({ limit: 1, skip: 0, cardNo, regionId });

const getSettingCardConfigurations = (newHeader) => {
    return fetchGet(
        `${BASE_URL}cardconfigurations?${jsonToQueryParam(newHeader)}`,
        true,
        getPermissionPath(
            AccessPermissionModuleNames.CARD,
            AccessPermissionModules[AccessPermissionModuleNames.CARD].actions
                .ListCardConfigurations
        )
    );
};

const editSettingCard = ({ _id, rangeFrom, rangeTo, replacementFee }) => {
    return fetchPut(
        `${BASE_URL}cardconfigurations/${_id}`,
        {
            rangeFrom,
            rangeTo,
            replacementFee,
        },
        true,
        getPermissionPath(
            AccessPermissionModuleNames.CARD,
            AccessPermissionModules[AccessPermissionModuleNames.CARD].actions
                .UpdateCardConfiguration
        )
    );
};

const getAllCardsOfMember = async ({ regionId, ...args }) => {
    const limit = 100;
    let totalCount = 0,
        cardsOfMember = [];

    try {
        do {
            const cardsResponse = await getCardList({
                limit,
                skip: cardsOfMember.length,
                regionId,
                ...args,
            });
            totalCount = cardsResponse.total;
            cardsOfMember = [...cardsOfMember, ...cardsResponse.items];
        } while (cardsOfMember.length < totalCount);

        return { items: cardsOfMember, total: totalCount };
    } catch (error) {
        return Promise.reject(error);
    }
};

export {
    createCardBatchJob,
    getCardBatchJobs,
    updateCardBatchJobs,
    getCardList,
    requestEmbossedCardForMember,
    exportCardList,
    assignCardToALoyaltyId,
    updateCardStatus,
    getCardSummary,
    getCardByCardNumber,
    getCardStock,
    getSettingCardConfigurations,
    editSettingCard,
    createCardDistributionJobs,
    createCardPrintJobs,
    exportFailedEmbossedCardList,
    getAllCardsOfMember,
};
