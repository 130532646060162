const TransactionStatus = {
    VALID : "VALID",
    INVALID : "INVALID"
}

const InvalidTransactionStatus = {
    PENDING_ACTION : "PENDING_ACTION",
    RESOLVED : "RESOLVED",
    DISCARDED : "DISCARDED"
}

const InvalidTransactionStatusVariant = {
    PENDING_ACTION : "warning",
    RESOLVED : "success",
    DISCARDED : "danger"
}

const TransactionStatusColorCode = {
    [TransactionStatus.VALID]: "olive",
    [TransactionStatus.INVALID]: "danger"
}

export {
    TransactionStatus, 
    InvalidTransactionStatus,
    InvalidTransactionStatusVariant,
    TransactionStatusColorCode
};