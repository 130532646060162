import { toast } from "react-toastify";
import { toTitleCase } from "./StringUtils";

const isEqualObjects = (a, b) => {
    try {
        let s = (o) =>
            o
                ? Object.entries(o)
                    .sort()
                    .map((i) => {
                        if (i[1] instanceof Object) i[1] = s(i[1]);
                        return i;
                    })
                : o;
        return JSON.stringify(s(a)) === JSON.stringify(s(b));
    } catch (error) {
        console.error(error);
        toast.error(
            <div>
                Failed to compare objects!
                <br />
                {error.message
                    ? `Error: ${error.message}`
                    : "Please try again later."}
            </div>
        );
        return false;
    }
};

const isEmptyObject = (object) => {
    return Object.keys(object).length === 0;
};

const removeKeysFromObject = (obj, keys) => {
    console.debug(obj);
    return Object.entries(obj).reduce((result, [keyName, value]) => {
        if (!keys.includes(keyName)) {
            if (typeof value === "object" && !Array.isArray(value)) {
                result[keyName] = removeKeysFromObject(value, keys);
            } else {
                result[keyName] = value;
            }
        }
        return result;
    }, {});
};

const getValueFromObject = (object, path) => {
    return path.split(".").reduce((r, k) => r?.[k], object);
};

const objectToLabelValueArray = (items) =>
    Object.keys(items).map((item) => ({
        value: item,
        label: toTitleCase(items[item]),
    }));

const stringIsJson = (item) => {
    let value = typeof item !== "string" ? JSON.stringify(item) : item;

    try {
        value = JSON.parse(value);
    } catch (e) {
        console.error("Invalid JSON string", e);
        return false;
    }

    return typeof value === "object" && value !== null;
};

export {
    isEqualObjects,
    isEmptyObject,
    removeKeysFromObject,
    getValueFromObject,
    objectToLabelValueArray,
    stringIsJson,
};
