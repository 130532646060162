import {
    AccessPermissionModuleNames,
    AccessPermissionModules,
    MessageLogCategories,
} from "Data";
import { getPermissionPath } from "Utils";
import { fetchGet, jsonToQueryParam } from "./CommonServiceUtils";
import Constants from "../Constants";

const BASE_URL = `${Constants.BASE_URL}messageservice/`;

const getMessageLogs = (messageLogsPayload) => {
    return fetchGet(
        `${BASE_URL}messagelogs?${jsonToQueryParam(messageLogsPayload)}`,
        true
        // TODO: [SHTT-967]: Add permissions to message service endpoints.
    );
};

const getMessageLogsCount = (messageLogsPayload) => {
    return fetchGet(
        `${BASE_URL}messagelogs/count?${jsonToQueryParam(messageLogsPayload)}`,
        true
        // TODO: [SHTT-967]: Add permissions to message service endpoints.
    );
};

const exportMessageLogs = (messageLogsPayload) => {
    return fetchGet(
        `${BASE_URL}messagelogs/export?${jsonToQueryParam(messageLogsPayload)}`,
        true
        // TODO: [SHTT-967]: Add permissions to message service endpoints.
    );
};

const getNotificationLogsFromMessageService = (filters = {}) => {
    const queryParams = { ...filters, category: MessageLogCategories.CORE };

    return fetchGet(
        `${BASE_URL}messagelogs?${jsonToQueryParam(queryParams)}`,
        true,
        getPermissionPath(
            AccessPermissionModuleNames.NOTIFICATION,
            AccessPermissionModules[AccessPermissionModuleNames.NOTIFICATION]
                .actions.ListNotificationLogs
        )
    );
};

const getNotificationLogsCountFromMessageService = (filters = {}) => {
    const queryParams = { ...filters, category: MessageLogCategories.CORE };

    return fetchGet(
        `${BASE_URL}messagelogs/count?${jsonToQueryParam(queryParams)}`,
        true,
        getPermissionPath(
            AccessPermissionModuleNames.NOTIFICATION,
            AccessPermissionModules[AccessPermissionModuleNames.NOTIFICATION]
                .actions.ListNotificationLogs
        )
    );
};

export {
    getMessageLogs,
    exportMessageLogs,
    getMessageLogsCount,
    getNotificationLogsFromMessageService,
    getNotificationLogsCountFromMessageService,
};
