import {
    fetchGet,
    jsonToQueryParam,
    fetchPut,
    fetchPost,
    fetchDelete,
    fetchPostMultipart,
} from "./CommonServiceUtils";
import Constants from "../Constants";
import { AccessPermissionModules, AccessPermissionModuleNames } from "Data";
import { getPermissionPath } from "Utils";

const BASE_URL = `${Constants.BASE_URL}loyaltyservice/`;

const getTransactions = (queryObj, limit, skip) => {
    // * Query params: limit, skip, merchantId
    return fetchGet(
        `${BASE_URL}transactions?${jsonToQueryParam({
            ...queryObj,
            limit,
            skip,
        })}`,
        true,
        getPermissionPath(
            AccessPermissionModuleNames.TRANSACTION,
            AccessPermissionModules[AccessPermissionModuleNames.TRANSACTION]
                .actions.ListTransactions
        )
    );
};

const getTransactionsCount = (queryObj) => {
    return fetchGet(
        `${BASE_URL}transactions/count?${jsonToQueryParam(queryObj)}`,
        true,
        getPermissionPath(
            AccessPermissionModuleNames.TRANSACTION,
            AccessPermissionModules[AccessPermissionModuleNames.TRANSACTION]
                .actions.ListTransactions
        )
    );
};

const exportTransactions = (queryObj) => {
    return fetchGet(
        `${BASE_URL}transactions/export?${jsonToQueryParam(queryObj)}`,
        true,
        getPermissionPath(
            AccessPermissionModuleNames.TRANSACTION,
            AccessPermissionModules[AccessPermissionModuleNames.TRANSACTION]
                .actions.ListTransactions
        )
    );
};

const getInvalidTransactions = (queryObj) => {
    // * Query params: limit, skip, merchantId, status, importJobId, subType
    return fetchGet(
        `${BASE_URL}stagedtransactions?${jsonToQueryParam(queryObj)}`,
        true,
        getPermissionPath(
            AccessPermissionModuleNames.TRANSACTION,
            AccessPermissionModules[AccessPermissionModuleNames.TRANSACTION]
                .actions.ListStagedTransaction
        )
    );
};

const archiveInvalidTransaction = (id) => {
    return fetchDelete(
        BASE_URL + "stagedtransactions/" + id,
        "",
        getPermissionPath(
            AccessPermissionModuleNames.TRANSACTION,
            AccessPermissionModules[AccessPermissionModuleNames.TRANSACTION]
                .actions.DeleteStagedTransaction
        )
    );
};

const linkLoyaltyCardToTransaction = (id, payload) => {
    return fetchPut(
        `${BASE_URL}stagedtransactions/${id}`,
        payload,
        true,
        getPermissionPath(
            AccessPermissionModuleNames.TRANSACTION,
            AccessPermissionModules[AccessPermissionModuleNames.TRANSACTION]
                .actions.UpdateStagedTransaction
        )
    );
};

const getImportTransactions = (queryObj) => {
    return fetchGet(
        `${BASE_URL}transactionimportjobs?${jsonToQueryParam(queryObj)}`,
        true,
        getPermissionPath(
            AccessPermissionModuleNames.TRANSACTION,
            AccessPermissionModules[AccessPermissionModuleNames.TRANSACTION]
                .actions.ListTransactionImportJobs
        )
    );
};

const importTransactionFileUpload = (queryObj, fileFormData) => {
    return fetchPostMultipart(
        `${BASE_URL}transactionimportjobs/uploadfile?${jsonToQueryParam(
            queryObj
        )}`,
        fileFormData
    );
};

const importTransaction = (payload) => {
    return fetchPost(
        BASE_URL + "transactionimportjobs",
        payload,
        true,
        getPermissionPath(
            AccessPermissionModuleNames.TRANSACTION,
            AccessPermissionModules[AccessPermissionModuleNames.TRANSACTION]
                .actions.CreateTransactionImportJob
        )
    );
};

export {
    getTransactions,
    getInvalidTransactions,
    archiveInvalidTransaction,
    linkLoyaltyCardToTransaction,
    getImportTransactions,
    importTransaction,
    importTransactionFileUpload,
    getTransactionsCount,
    exportTransactions,
};
