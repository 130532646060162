import React from "react";
import { IcIcon } from "@shoutout-labs/shoutout-themes-enterprise";
import { toTitleCase } from "Utils";

const NameIconTemplate = ({ name, icon }) => (
    <div className="d-flex align-items-center">
        {icon && <IcIcon className="mr-2 text-black" size="lg" icon={icon} />}
        <div className="py-1">{name && toTitleCase(name)}</div>
    </div>
);

export default NameIconTemplate;
