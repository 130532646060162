import { toTitleCase } from "Utils";

const UserBoundaryType = {
    ROOT: "ROOT",
    GLOBAL: "GLOBAL", // * Global admin
    REGIONAL: "REGIONAL", // * Regional admin,
    MERCHANT: "MERCHANT", // * Only has access to the defined merchants
};

const UserBoundaryTypeColorCode = {
    [UserBoundaryType.ROOT]: "danger",
    [UserBoundaryType.GLOBAL]: "purple",
    [UserBoundaryType.REGIONAL]: "orange",
    [UserBoundaryType.MERCHANT]: "info",
};

const UserBoundaryTypeOptions = Object.values(UserBoundaryType)
    .filter((uBT) => uBT !== UserBoundaryType.ROOT)
    .map((fUBT) => ({
        label: toTitleCase(fUBT),
        value: fUBT,
    }));

export { UserBoundaryType, UserBoundaryTypeColorCode, UserBoundaryTypeOptions };
