//import { array } from "prop-types";
import qs from "qs";
const getHeaders = async (isAuth, userPermissions = null) => {
    const headers = { "Content-Type": "application/json" };
    if (isAuth) {
        headers["x-auth"] = true;
    }
    if (userPermissions) {
        headers["x-auth-scope"] = userPermissions;
    }
    return headers;
};
const handleErrors = async (response) => {
    if (!response.ok) {
        let errorResponse;
        try {
            errorResponse = await response.json();
        } catch (e) {
            throw Error(response.statusText);
        }
        throw Error(errorResponse?.error);
    }
    return response;
};

const parseJson = (response) => {
    try {
        return response.json();
    } catch (e) {
        console.error("Cannot parse:", response);
        throw Error("Can not parse");
    }
};

const parseText = (response) => {
    try {
        return response.text();
    } catch (e) {
        console.error("Cannot parse:", response);
        throw Error("Can not parse");
    }
};
const fetchGet = async (url, isAuth = true, userPermissions = null) => {
    return fetch(url, { headers: await getHeaders(isAuth, userPermissions) })
        .then(handleErrors)
        .then(parseJson);
};

const fetchGetWithTotal = async (
    url,
    isAuth = true,
    userPermissions = null
) => {
    return fetch(url, { headers: await getHeaders(isAuth, userPermissions) })
        .then(handleErrors)
        .then(async (response) => {
            const jsonBody = await parseJson(response);
            const result = { data: jsonBody };
            if (response.headers) {
                const totalCount = response.headers.get("x-total"),
                    skip = response.headers.get("x-skip"),
                    limit = response.headers.get("x-limit");

                result["totalCount"] = totalCount ? Number(totalCount) : 0;
                result["skip"] = skip ? Number(skip) + Number(limit) : 0;
            }

            return result;
        });
};

const fetchPostWithTotal = async (
    url,
    body,
    isAuth = true,
    userPermissions = null
) => {
    return fetch(url, {
        headers: await getHeaders(isAuth, userPermissions),
        method: "POST",
        body: JSON.stringify(body),
    })
        .then(handleErrors)
        .then(async (response) => {
            const jsonBody = await parseJson(response);
            const result = { data: jsonBody };
            if (response.headers) {
                const totalCount = response.headers.get("x-total"),
                    skip = response.headers.get("x-skip"),
                    limit = response.headers.get("x-limit");

                result["totalCount"] = totalCount ? Number(totalCount) : 0;
                result["skip"] = skip ? Number(skip) + Number(limit) : 0;
            }

            return result;
        });
};
const fetchPost = async (url, body, isAuth = true, userPermissions = null) => {
    return fetch(url, {
        headers: await getHeaders(isAuth, userPermissions),
        method: "POST",
        body: JSON.stringify(body),
    })
        .then(handleErrors)
        .then(parseJson);
};

const fetchGetText = async (url, isAuth = true, userPermissions = null) => {
    const headers = await getHeaders(isAuth, userPermissions);
    headers["Content-Type"] = "text/plain";
    return fetch(url, { headers: await getHeaders(isAuth) })
        .then(handleErrors)
        .then(parseText);
};

const fetchPut = async (url, body, isAuth = true, userPermissions = null) => {
    return fetch(url, {
        method: "PUT",
        headers: await getHeaders(isAuth, userPermissions),
        body: JSON.stringify(body),
    })
        .then(handleErrors)
        .then(parseJson);
};

const fetchDelete = async (
    url,
    body,
    track,
    isAuth = true,
    userPermissions = null
) => {
    return fetch(url, {
        method: "DELETE",
        // headers: {
        //     'Content-Type': 'application/json'
        // },
        headers: await getHeaders(isAuth, userPermissions),
        isProtected: true,
        showLoader: true,
        body: body ? JSON.stringify(body) : "",
        track,
    }).then(handleErrors);
};

const jsonToQueryParam = (json) => {
    if (json) {
        // Object.entries(json).map(([key, value]) => {

        //     if (value !== null || value !== undefined || value === "") {
        //         if (Array.isArray(value)) {

        //             value.forEach(element => {
        //                 result += `${result ? '&' : ''}${key}[]=${element}`;
        //             });
        //         } else {
        //             result += `${result ? '&' : ''}${key}=${value}`;
        //         }

        //     }
        // })
        return qs.stringify(json, {
            filter: (prefix, value) => {
                if (value===""||value===null) return;
                return value;
            },
            encodeValuesOnly: true,
            indices: false,
            arrayFormat: 'brackets'
        });
        // Object.entries(json).forEach(([key, value]) => {
        //     if (value !== "" && value !== null && value !== undefined) {
        //         if (Array.isArray(value)) {
        //             value.forEach((element) => {
        //                 result += `${result ? "&" : ""}${key}[]=${element}`;
        //             });
        //         } else {
        //             result += `${result ? "&" : ""}${key}=${value}`;
        //         }
        //     }
        // });
    }

    return "";
};

const preProcessBodyData = (json) => {
    return Object.entries(json).reduce((result, [key, value]) => {
        if (typeof value === "object" && !Array.isArray(value)) {
            result[key] = preProcessBodyData(value);
        } else if (value || (key && value === false)) {
            result[key] = value;
        }
        return result;
    }, {});
};

const fetchPostText = (
    url,
    body,
    showLoader = true,
    userPermissions = null
) => {
    return fetch(url, {
        method: "POST",
        headers: {
            "Content-Type": "application/json",
            "x-auth-scope": userPermissions,
        },
        isProtected: true,
        showLoader,
        body: JSON.stringify(body),
    })
        .then(handleErrors)
        .then(parseText);
};

const fetchPostMultipart = async (
    url,
    body,
    isAuth = true,
    userPermissions = null
) => {
    const headers = await getHeaders(isAuth, userPermissions);
    delete headers["Content-Type"]; // = 'application/json';
    return fetch(url, {
        method: "POST",
        headers: headers,
        body: body,
    })
        .then(handleErrors)
        .then(parseJson);
};

export {
    fetchGet,
    fetchPost,
    fetchGetText,
    fetchGetWithTotal,
    fetchPut,
    fetchDelete,
    handleErrors,
    parseJson,
    jsonToQueryParam,
    fetchPostText,
    fetchPostWithTotal,
    fetchPostMultipart,
    preProcessBodyData,
};
