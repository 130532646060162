import moment from "moment";
import { toTitleCase } from "Utils";
import { convertArrayToObject } from "Components/merchants/utils/MerchantUtility";

const MerchantTypes = {
    INTERNAL: "INTERNAL",
    EXTERNAL: "EXTERNAL",
};

const MerchantProfileTabs = {
    REPORT: "REPORT",
    INTEGRATIONS: "INTEGRATIONS",
    CONTACT_DETAILS: "CONTACT_DETAILS",
    USERS: "USERS",
    LOCATIONS: "LOCATIONS",
    TRANSACTIONS: "TRANSACTIONS",
};

const MerchantStatus = {
    DRAFT: "DRAFT",
    ACTIVE: "ACTIVE",
    SUSPENDED: "SUSPENDED",
};

const NearestLocationStatus = [
    {
        value: true,
        name: "Yes",
    },
    {
        value: false,
        name: "No",
    },
];

const MerchantOptions = [
    {
        value: "adjustPoints",
        name: "Adjust Points",
    },
    {
        value: "enroll",
        name: "Enroll",
    },
    {
        value: "earn",
        name: "Earn",
    },
    {
        value: "redeemPoints",
        name: "Redeem Points",
    },
    {
        value: "redeemRewards",
        name: "Redeem Rewards",
    },
    {
        value: "refund",
        name: "Refund",
    },
    {
        value: "void",
        name: "Void",
    },
    {
        value: "claimReward",
        name: "Claim Reward",
    },
];

const MerchantPickUpLocationOptions = [
    {
        value: "TRUE",
        name: "true",
    },
    {
        value: "FALSE",
        name: "false",
    },
];

const LocationEnableForTransactionOptions = [
    {
        value: true,
        name: "True",
    },
    {
        value: false,
        name: "False",
    },
];

const LocationStatus = {
    DRAFT: "DRAFT",
    ACTIVE: "ACTIVE",
    SUSPENDED: "SUSPENDED",
};

const MerchantLocationStatus = Object.keys(LocationStatus).map((lS) => ({
    value: lS,
    name: toTitleCase(lS),
}));

const MerchantTransactionFilter = [
    {
        value: "LOCATION",
        name: "Location",
    },
    {
        value: "TRANSACTION_TYPE",
        name: "Transaction Type",
    },
];

const MerchantTransactionType = [
    {
        value: "COLLECTION",
        name: "Collection",
    },
    {
        value: "REDEMPTION",
        name: "Redemption",
    },
    {
        value: "ADJUSTMENT",
        name: "Adjustment",
    },
];

const MerchantTypesAsObjArray = Object.keys(MerchantTypes).map((mTAOA) => ({
    value: mTAOA,
    name: toTitleCase(mTAOA),
}));

const MerchantStatusAsObjArray = Object.keys(MerchantStatus).map((mSAOA) => ({
    value: mSAOA,
    name: toTitleCase(mSAOA),
}));

const MerchantLocationStatusObj = convertArrayToObject(
    MerchantLocationStatus,
    "value",
    null
);

const MerchantTransactionTypeObj = convertArrayToObject(
    MerchantTransactionType,
    "value",
    null
);

const MerchantLocationPickUpObj = convertArrayToObject(
    MerchantPickUpLocationOptions,
    "name",
    null
);

const MerchantTypeColorCode = {
    [MerchantTypes.EXTERNAL]: "info",
    [MerchantTypes.INTERNAL]: "secondary",
};

const MerchantTypeTextColorCode = {
    [MerchantTypes.EXTERNAL]: "text-info",
    [MerchantTypes.INTERNAL]: "text-secondary",
};

const MerchantStatusColorCode = {
    [MerchantStatus.DRAFT]: "dark",
    [MerchantStatus.ACTIVE]: "success",
    [MerchantStatus.SUSPENDED]: "danger",
};

const NearestLocationStatusColorCode = {
    [false]: "dark",
    [true]: "success",
};

const NearestLocationStatusTextColorCode = {
    [false]: "text-dark",
    [true]: "text-success",
};

const MerchantStatusTextColorCode = {
    [MerchantStatus.DRAFT]: "text-dark",
    [MerchantStatus.ACTIVE]: "text-success",
    [MerchantStatus.SUSPENDED]: "text-danger",
};

const MerchantLocationStatusColorCode = {
    [MerchantLocationStatusObj.DRAFT]: "dark",
    [MerchantLocationStatusObj.ACTIVE]: "success",
    [MerchantLocationStatusObj.SUSPENDED]: "danger",
};

const MerchantLMerchantTransactionTypeColorCode = {
    [MerchantTransactionTypeObj.ADJUSTMENT]: {
        color: "white",
        backgroundColor: "#8a00e6",
    },
    [MerchantTransactionTypeObj.COLLECTION]: {
        color: "white",
        backgroundColor: "#21b73f",
    },
    [MerchantTransactionTypeObj.REDEMPTION]: {
        color: "white",
        backgroundColor: "#fd7e14",
    },
};

const DatePeriods = {
    "7 Days": {
        toDate: moment().format("YYYY-MM-DD"),
        fromDate: moment().subtract(6, "day").format("YYYY-MM-DD"),
        selectedTimePeriod: "prev. 7 days",
    },
    "28 Days": {
        toDate: moment().format("YYYY-MM-DD"),
        fromDate: moment().subtract(27, "day").format("YYYY-MM-DD"),
        selectedTimePeriod: "prev. 28 days",
    },
    "90 Days": {
        toDate: moment().format("YYYY-MM-DD"),
        fromDate: moment().subtract(89, "day").format("YYYY-MM-DD"),
        selectedTimePeriod: "prev. 90 days",
    },
    "365 Days": {
        toDate: moment().format("YYYY-MM-DD"),
        fromDate: moment().subtract(364, "day").format("YYYY-MM-DD"),
        selectedTimePeriod: "prev. 365 days",
    },
};

export {
    MerchantTypes,
    MerchantTypesAsObjArray,
    MerchantStatus,
    MerchantStatusAsObjArray,
    MerchantOptions,
    MerchantPickUpLocationOptions,
    LocationStatus,
    MerchantLocationStatus,
    MerchantLocationStatusObj,
    MerchantLocationPickUpObj,
    MerchantTypeColorCode,
    MerchantTypeTextColorCode,
    MerchantStatusColorCode,
    MerchantStatusTextColorCode,
    MerchantLocationStatusColorCode,
    MerchantTransactionFilter,
    MerchantTransactionType,
    MerchantLMerchantTransactionTypeColorCode,
    MerchantProfileTabs,
    DatePeriods,
    NearestLocationStatus,
    NearestLocationStatusColorCode,
    NearestLocationStatusTextColorCode,
    LocationEnableForTransactionOptions,
};
