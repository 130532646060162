import React from "react";
import PropTypes from "prop-types";
import { FormCheck } from "@shoutout-labs/shoutout-themes-enterprise";

const BooleanValuePicker = ({
    name,
    value,
    trueValue,
    falseValue,
    trueLabel,
    falseLabel,
    disabled,
    onChange,
}) => {
    return (
        <div className="m-0 d-flex align-items-center">
            <div
                id={trueValue}
                data-radio-value={trueValue}
                onClick={disabled ? () => {} : onChange}
            >
                <FormCheck
                    className="mx-1"
                    id={trueValue}
                    name={`${name}-true`}
                    type="radio"
                    label={trueLabel}
                    value={trueValue}
                    disabled={disabled}
                    onChange={onChange}
                    checked={trueValue === value}
                    custom
                    required
                />
            </div>
            <div
                id={falseValue}
                data-radio-value={falseValue}
                onClick={disabled ? () => {} : onChange}
            >
                <FormCheck
                    className="mx-1"
                    id={falseValue}
                    name={`${name}-false`}
                    type="radio"
                    label={falseLabel}
                    disabled={disabled}
                    value={falseValue}
                    onChange={onChange}
                    checked={falseValue === value}
                    custom
                    required
                />
            </div>
        </div>
    );
};

BooleanValuePicker.defaultProps = {
    trueValue: "true",
    falseValue: "false",
    trueLabel: "Yes",
    falseLabel: "No",
    disabled: false,
    onChange: () => {},
};

BooleanValuePicker.propTypes = {
    name: PropTypes.string.isRequired,
    value: PropTypes.string.isRequired,
    trueValue: PropTypes.string,
    falseValue: PropTypes.string,
    trueLabel: PropTypes.string,
    falseLabel: PropTypes.string,
    disabled: PropTypes.bool,
    onChange: PropTypes.func,
};

export default BooleanValuePicker;
