import React, { useCallback, useContext, useMemo, useState } from "react";
import { useHistory, useLocation } from "react-router-dom";
import {
    DropdownButton,
    DropdownItem,
    IcIcon,
    OverlayTrigger,
    Tooltip,
} from "@shoutout-labs/shoutout-themes-enterprise";
import defaultLogo from "assets/images/shoutout_logo.png";
import {
    faGlobe,
    faSetting,
    faUsers,
    faChartLine,
    faUilTelegramAlt,
    faUilUserCircle,
    faHeartMedical,
} from "FaICIconMap";
import { UserContext } from "Contexts";

import "./SidebarMenu.scss";

const navList = [
    { tab: "Members", name: "Members", icon: faUsers },
    { tab: "Loyalty", name: "Loyalty", icon: faHeartMedical },
    {
        tab: "Campaign",
        name: "Notifications & Campaigns",
        icon: faUilTelegramAlt,
    },
    { tab: "Analytics", name: "Analytics & Jobs", icon: faChartLine },
    { tab: "Control Panel", name: "Control Panel", icon: faSetting },
];

const SidebarMenu = ({ setActiveMainTab, activeMainTab }) => {
    const {
        organization,
        userRegions,
        onSelectRegion,
        selectedRegion: region,
    } = useContext(UserContext);
    const [dropdownIsActive, setDropdownIsActive] = useState(false);
    const location = useLocation();
    const history = useHistory();

    const isGlobalSettings = useMemo(
        () => !!location.pathname.includes("/control-panel/"),
        [location]
    );

    const logo = useMemo(
        () => organization?.organizationLogoImageUrl || defaultLogo,
        [organization?.organizationLogoImageUrl]
    );

    const navigateToUserProfile = useCallback(
        (e) => {
            e.stopPropagation();
            e.preventDefault();
            history.push("/profile");
            setActiveMainTab("Profile");
        },
        [history, setActiveMainTab]
    );

    const onClickNavItem = useCallback(
        (event) => {
            event.stopPropagation();
            setActiveMainTab(event.currentTarget.dataset.tab);
        },
        [setActiveMainTab]
    );

    const onClickRegion = useCallback(
        (event) => {
            setDropdownIsActive(!dropdownIsActive);
        },
        [dropdownIsActive, setDropdownIsActive]
    );

    return (
        <div className="sidebar">
            <div>
                <div className="logo-container">
                    <img
                        className="p-1 logo"
                        src={logo}
                        alt="Organization Logo"
                    />
                </div>
                <div className="brand">
                    {isGlobalSettings ? (
                        <div>
                            <IcIcon
                                icon={faGlobe}
                                className="mr-2 active-globe"
                                size="lg"
                            />
                        </div>
                    ) : (
                        <>
                            <DropdownButton
                                data-testid="region-selection-dropdown"
                                bsPrefix={`btn w-100 ${
                                    dropdownIsActive
                                        ? "region-dropdown-menu-active"
                                        : "mb-3"
                                }`}
                                title={
                                    region && (
                                        <>
                                            {region.regionIconUrl && (
                                                <img
                                                    src={region.regionIconUrl}
                                                    className="flags-img  mr-1 mb-1"
                                                    alt={region.regionName}
                                                />
                                            )}
                                        </>
                                    )
                                }
                                onSelect={onSelectRegion}
                                onToggle={onClickRegion}
                            >
                                {userRegions.map(
                                    ({ _id, regionIconUrl, regionName }) => (
                                        <DropdownItem
                                            key={_id}
                                            eventKey={_id}
                                            className="py-1"
                                        >
                                            {region.regionIconUrl && (
                                                <img
                                                    className="flags-img mr-2"
                                                    src={regionIconUrl}
                                                    alt={regionName}
                                                />
                                            )}
                                            {regionName || "~ unknown"}
                                        </DropdownItem>
                                    )
                                )}
                            </DropdownButton>
                        </>
                    )}
                </div>
                <div>
                    <div className="nav-list cursor-pointer">
                        {navList.map((nav) => (
                            <OverlayTrigger
                                key={nav.tab}
                                placement="right"
                                overlay={
                                    <Tooltip id="tooltip-side-nav">
                                        {nav.name}
                                    </Tooltip>
                                }
                            >
                                <div
                                    className={
                                        activeMainTab === nav.tab
                                            ? "active-nav-item"
                                            : "nav-item"
                                    }
                                    data-tab={nav.tab}
                                    onClick={onClickNavItem}
                                    key={nav.tab}
                                >
                                    <span className="nav-item__icon">
                                        <IcIcon
                                            icon={nav.icon}
                                            className="mr-2"
                                            size="lg"
                                        />
                                    </span>
                                </div>
                            </OverlayTrigger>
                        ))}
                    </div>
                </div>
            </div>
            <div>
                <div className="nav-list mb-2 cursor-pointer">
                    <OverlayTrigger
                        placement="right"
                        overlay={
                            <Tooltip id="tooltip-side-nav-profile">
                                Profile
                            </Tooltip>
                        }
                    >
                        <div
                            className={
                                activeMainTab === "Profile"
                                    ? "active-nav-item"
                                    : "nav-item"
                            }
                            data-tab="Profile"
                            onClick={navigateToUserProfile}
                        >
                            <span className="nav-item__icon">
                                <IcIcon
                                    icon={faUilUserCircle}
                                    className="mr-2"
                                    size="lg"
                                />
                            </span>
                        </div>
                    </OverlayTrigger>
                </div>
            </div>
        </div>
    );
};

export default SidebarMenu;
