const CardStatus = {
    ACTIVE: "ACTIVE",
    SUSPENDED: "SUSPENDED",
    PENDING: "PENDING",
    ASSIGNED: "ASSIGNED",
    DEACTIVATED: "DEACTIVATED",
    FAILED: "FAILED",
    READY: "READY",
};

const CardStatusVariant = {
    [CardStatus.PENDING]: "warning",
    [CardStatus.ACTIVE]: "success",
    [CardStatus.ASSIGNED]: "secondary",
    [CardStatus.DEACTIVATED]: "danger",
    [CardStatus.FAILED]: "danger",
    [CardStatus.SUSPENDED]: "orange",
    [CardStatus.READY]: "info",
};

export { CardStatus, CardStatusVariant };
