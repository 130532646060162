import {
    faCalendar,
    faCardAtm,
    faCheck,
    faCreditCard,
    faDispatch,
    faObjectGroup,
    faPrint,
    faStore,
} from "FaICIconMap";
import NameIconTemplate from "Components/utils/table/NameIconTemplate";

const InstantCardsStatus = {
    CARD_STOCK: "CARD_STOCK",
    CARD_DISTRIBUTION: "CARD_DISTRIBUTION",
    CARD_PRINTING: "CARD_PRINTING",
};

const JobURLParams = {
    CARD_DISTRIBUTION: "carddistributionjobs",
    CARD_PRINTING: "cardprintjobs",
};
const ExportJobURLParams = {
    CARD_DISTRIBUTION: "carddistributionjobs/getcsv",
    CARD_PRINTING: "cardprintjobs/getcsv",
};

const cardDistributionStatus = {
    DISTRIBUTE: "DISTRIBUTE",
    PROCESSING: "PROCESSING",
    DISPATCHED: "DISPATCHED",
    COMPLETED: "COMPLETED",
    FAILED: "FAILED",
};
const cardPrintingStatus = {
    GENERATE: "GENERATE",
    PENDING: "PENDING",
    PRINTING: "PRINTING",
    COMPLETED: "COMPLETED",
    FAILED: "FAILED",
};

const cardDistributionTab = [
    {
        eventKey: cardDistributionStatus.DISTRIBUTE,
        title: "Distribute",
    },
    {
        eventKey: cardDistributionStatus.PROCESSING,
        title: "Processing",
    },
    {
        eventKey: cardDistributionStatus.DISPATCHED,
        title: "Dispatched",
    },
    {
        eventKey: cardDistributionStatus.COMPLETED,
        title: "Completed",
    },
    {
        eventKey: cardDistributionStatus.FAILED,
        title: "Failed",
    },
];

const cardPrintingTab = [
    {
        eventKey: cardPrintingStatus.GENERATE,
        title: "Generate",
    },
    {
        eventKey: cardPrintingStatus.PENDING,
        title: "Pending",
    },
    {
        eventKey: cardPrintingStatus.PRINTING,
        title: "Printing",
    },
    {
        eventKey: cardPrintingStatus.COMPLETED,
        title: "Completed",
    },
    {
        eventKey: cardPrintingStatus.FAILED,
        title: "Failed",
    },
];

const defaultColumns = {
    CARD_DISTRIBUTION: [
        {
            dataField: "batchId",
            text: NameIconTemplate({ name: "Batch Id", icon: faCardAtm }),
            headerStyle: { width: "15%" },
        },
        {
            dataField: "quantity",
            text: NameIconTemplate({ name: "Quantity", icon: faCreditCard }),
            headerStyle: { width: "10%" },
        },

        {
            dataField: "merchantName",
            text: NameIconTemplate({ name: "Merchant", icon: faStore }),
        },
        {
            dataField: "locationName",
            text: NameIconTemplate({
                name: "Merchant Location",
                icon: faStore,
            }),
        },
        {
            dataField: "cardType",
            text: NameIconTemplate({ name: "Card Type", icon: faObjectGroup }),
        },
        {
            dataField: "createdOn",
            text: NameIconTemplate({ name: "Created Date", icon: faCalendar }),
        },
        {
            dataField: "action",
        },
    ],
    CARD_PRINTING: [
        {
            dataField: "batchId",
            text: NameIconTemplate({ name: "Batch Id", icon: faCardAtm }),
        },
        {
            dataField: "quantity",
            text: NameIconTemplate({ name: "Quantity", icon: faCreditCard }),
        },
        {
            dataField: "cardType",
            text: NameIconTemplate({ name: "Card Type", icon: faObjectGroup }),
        },
        {
            dataField: "updatedOn",
            text: NameIconTemplate({ name: "Updated Date", icon: faCalendar }),
        },
        {
            dataField: "action",
        },
    ],
};
const columnsItemGoingToChange = {
    CARD_DISTRIBUTION: "createdOn",
    CARD_PRINTING: "updatedOn",
};
const batchJobIdType = {
    CARD_DISTRIBUTION: "distributionJobId",
    CARD_PRINTING: "printJobId",
};

const nextStatus = {
    PENDING: {
        nextStep: cardPrintingStatus.PRINTING,
        icon: faPrint,
        action: "Printing",
    },
    PRINTING: {
        nextStep: cardPrintingStatus.COMPLETED,
        icon: faCheck,
        action: "Completed",
    },
    PROCESSING: {
        nextStep: cardDistributionStatus.DISPATCHED,
        icon: faDispatch,
        action: "Dispatched",
    },
    DISPATCHED: {
        nextStep: cardDistributionStatus.COMPLETED,
        icon: faCheck,
        action: "Completed",
    },
};

export {
    JobURLParams,
    InstantCardsStatus,
    nextStatus,
    cardPrintingTab,
    cardDistributionTab,
    cardDistributionStatus,
    cardPrintingStatus,
    defaultColumns,
    columnsItemGoingToChange,
    ExportJobURLParams,
    batchJobIdType,
};
