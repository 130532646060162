import React, {
    useCallback,
    useContext,
    useEffect,
    useMemo,
    useReducer,
} from "react";
import { useHistory } from "react-router-dom";
import { toast } from "react-toastify";
import PropTypes from "prop-types";
import {
    DraftJS,
    exportText,
} from "@shoutout-labs/shoutout-message-editor-enterprise";
import { UserContext } from "./userContext";
import { DataContext } from "./dataContext";
import {
    CampaignTypes,
    IdTypeSelector,
    LaunchDates,
    MemberStatus,
    MemberTypes,
    PreferredContactMethods,
} from "Data";
import {
    convertEditorStateToHtml,
    createCampaign,
    filterMembers,
} from "Services";
import {
    buildMemberFilterConfig,
    convertTimeFrom12HTo24H,
    getDiffBetween2DatesInMinutes,
    getSegmentFilters,
    toTitleCase,
} from "Utils";

const { EditorState } = DraftJS;

const CreateCampaignContext = React.createContext();

// * To remove from showing in member filters, set the relevant field to an empty object.
const fieldsToBeExcludedFromFilters = {
    affinityGroup: {},
    affinityGroupId: {},
    pointsToExpire: {},
    identifications: {},
};

const equalAndNotEqualSelectOperators = ["select_equals", "select_not_equals"];

const identificationFitlers = IdTypeSelector.reduce(
    (result, idType) => ({
        ...result,
        [idType.key]: {
            label: idType.label,
            value: idType.value,
            type: "string",
        },
    }),
    {}
);

const resetCampaignStatus = {
    campaignType: "",
    isCreating: "",
    campaignName: "",
    campaignDescription: "",
    selectedProvider: [],
    to: [],
    selectSegments: "ALL",
    subject: "",
    selectLaunchDate: LaunchDates.NOW,
    launchDate: "",
    hours: "00",
    minutes: "00",
    timeOfDay: "",
    smsEditorState: null,
    emailEditorState: null,
    isNavigatedFromOutsideOfCampaignViews: false,
    appliedMemberFilters: [],
    filtersToApplyForMemberCount: [],
    isLoadingMemberCount: false,
    membersCountForSegmentsOrFilters: 0,
};

const initialState = {
    configuredProviders: [],
    filterConfig: {},
    ...resetCampaignStatus,
};

const CreateCampaignContextActions = {
    SET_CAMPAIGN_TYPE: "setCampaignType",
    SET_ATTRIBUTE: "setAttribute",
    SET_TO_SEGMENTS: "setToSegments",
    SET_LAUNCH_DATE: "setLaunchDate",
    SET_EDITOR_STATE: "setEditorState",
    SET_IS_CREATING: "setIsCreating",
    SET_MEMBER_FILTER_CONFIG: "setMemberFilterConfig",
    SET_IS_NAVIGATED_FROM_OUTSIDE: "setIsNavigatedFromOutside",
    SET_APPLIED_MEMBER_FILTERS: "setAppliedMemberFilters",
    RESET: "reset",
};

const reducer = (state, action) => {
    switch (action.type) {
        case CreateCampaignContextActions.SET_CAMPAIGN_TYPE: {
            return {
                ...state,
                campaignType: action.campaignType,
            };
        }
        case CreateCampaignContextActions.SET_MEMBER_FILTER_CONFIG: {
            return {
                ...state,
                filterConfig: action.filterConfig,
            };
        }
        case CreateCampaignContextActions.SET_ATTRIBUTE: {
            return {
                ...state,
                [action.key]: action.value,
            };
        }
        case CreateCampaignContextActions.SET_LAUNCH_DATE: {
            return {
                ...state,
                launchDate: action.date,
            };
        }
        case CreateCampaignContextActions.SET_TO_SEGMENTS: {
            return {
                ...state,
                to: action.toSegments,
                ...(action.isSetAppliedMemberFilters
                    ? {
                        appliedMemberFilters: action.toSegments.map(
                            (tSeg) => tSeg?.filter || {}
                        ),
                    }
                    : {}),
            };
        }
        case CreateCampaignContextActions.SET_EDITOR_STATE: {
            switch (action.transport) {
                case CampaignTypes.SMS: {
                    return {
                        ...state,
                        smsEditorState: action.editorState,
                    };
                }
                case CampaignTypes.EMAIL: {
                    return {
                        ...state,
                        emailEditorState: action.editorState,
                    };
                }
                default: {
                    return state;
                }
            }
        }
        case CreateCampaignContextActions.SET_IS_CREATING: {
            return {
                ...state,
                isCreating: action.status,
            };
        }
        case CreateCampaignContextActions.SET_IS_NAVIGATED_FROM_OUTSIDE: {
            return {
                ...state,
                isNavigatedFromOutsideOfCampaignViews: action.status,
            };
        }
        case CreateCampaignContextActions.SET_APPLIED_MEMBER_FILTERS: {
            return {
                ...state,
                appliedMemberFilters: action.appliedMemberFilters,
            };
        }
        case CreateCampaignContextActions.RESET: {
            return {
                ...state,
                ...resetCampaignStatus,
            };
        }
        default:
            return state;
    }
};

const getAllConfiguredProviders = (providersList = [], providerType = "") =>
    providersList.map((provider) => ({ ...provider, providerType }));

const validateAndGetLaunchDateTime = (
    payload,
    { selectLaunchDate, launchDate, hours, minutes, timeOfDay }
) => {
    if (selectLaunchDate === LaunchDates.LATER) {
        if (!launchDate) {
            throw new Error("Launch date cannot be empty!");
        }

        if (!hours || !minutes || !timeOfDay) {
            throw new Error("Launch time cannot be empty!");
        }

        const launchDateToDate = new Date(launchDate);
        const timeOfDayIn24H = convertTimeFrom12HTo24H(
            `${hours}:${minutes} ${timeOfDay}`
        );
        const [hh, mm] = timeOfDayIn24H.split(":");
        const launchDateAndTime = new Date(
            launchDateToDate.getFullYear(),
            launchDateToDate.getMonth(),
            launchDateToDate.getDate(),
            hh,
            mm
        );
        const launchDateToNowDiff = getDiffBetween2DatesInMinutes(
            launchDateAndTime,
            new Date()
        );
        if (launchDateToNowDiff < 60) {
            throw new Error(
                `Launch date and time must be at least an hour ahead of the current date and time! Difference: ${launchDateToNowDiff} ${
                    ~[-1, 1].indexOf(launchDateToNowDiff) ? "minute" : "minutes"
                }.`
            );
        }

        payload = {
            ...payload,
            scheduleOn: launchDateAndTime.toISOString(),
        };
    } else {
        payload = { ...payload };
    }
    return payload;
};

const CreateCampaignContextProvider = (props) => {
    const { regionId, selectedRegion, organization } = useContext(UserContext);
    const {
        contactAttributes,
        tags,
        tiers,
        affinityGroups,
        merchantLocations,
    } = useContext(DataContext);
    const [state, dispatch] = useReducer(reducer, initialState);
    const history = useHistory();

    const setCampaignType = useCallback(
        (campaignType) =>
            dispatch({
                type: CreateCampaignContextActions.SET_CAMPAIGN_TYPE,
                campaignType,
            }),
        [dispatch]
    );

    const setSelectedProvider = useCallback(
        (selectedProvider = []) =>
            dispatch({
                type: CreateCampaignContextActions.SET_ATTRIBUTE,
                key: "selectedProvider",
                value: selectedProvider,
            }),
        []
    );

    const setMemberFilterConfig = useCallback(
        (filterConfig) => {
            dispatch({
                type: CreateCampaignContextActions.SET_MEMBER_FILTER_CONFIG,
                filterConfig,
            });
        },
        [dispatch]
    );

    const setToSegments = useCallback(
        (toSegments = [], isSetAppliedMemberFilters = true) =>
            dispatch({
                type: CreateCampaignContextActions.SET_TO_SEGMENTS,
                toSegments,
                isSetAppliedMemberFilters,
            }),
        [dispatch]
    );

    const setAppliedMemberFilters = useCallback(
        (appliedMemberFilters) =>
            dispatch({
                type: CreateCampaignContextActions.SET_APPLIED_MEMBER_FILTERS,
                appliedMemberFilters,
            }),
        [dispatch]
    );

    const setFiltersToApplyForMemberCount = useCallback(
        (filters = []) =>
            dispatch({
                type: CreateCampaignContextActions.SET_ATTRIBUTE,
                key: "filtersToApplyForMemberCount",
                value: filters,
            }),
        [dispatch]
    );

    const setMembersCountForSegmentsOrFilters = useCallback(
        (count = 0) =>
            dispatch({
                type: CreateCampaignContextActions.SET_ATTRIBUTE,
                key: "membersCountForSegmentsOrFilters",
                value: count,
            }),
        [dispatch]
    );

    const setAttribute = useCallback(
        (event) => {
            const key = event.currentTarget.name || "";
            const value = event.currentTarget.value || "";

            if (key === "selectSegments" && value === "ALL") {
                setToSegments([], false);
                setAppliedMemberFilters([]);
                setFiltersToApplyForMemberCount([]);
                setMembersCountForSegmentsOrFilters(0);
            }

            dispatch({
                type: CreateCampaignContextActions.SET_ATTRIBUTE,
                key,
                value,
            });
        },
        [
            setToSegments,
            setAppliedMemberFilters,
            setFiltersToApplyForMemberCount,
            setMembersCountForSegmentsOrFilters,
            dispatch,
        ]
    );

    const setLaunchDate = useCallback(
        (date) =>
            dispatch({
                type: CreateCampaignContextActions.SET_LAUNCH_DATE,
                date,
            }),
        [dispatch]
    );

    const setLaunchTime = useCallback(
        ({ key = "", value = "" }) =>
            dispatch({
                type: CreateCampaignContextActions.SET_ATTRIBUTE,
                key,
                value,
            }),
        [dispatch]
    );

    const onChangeSmsEditor = useCallback(
        (editorState) =>
            dispatch({
                type: CreateCampaignContextActions.SET_EDITOR_STATE,
                editorState,
                transport: CampaignTypes.SMS,
            }),
        [dispatch]
    );

    const onChangeEmailEditor = useCallback(
        (editorState) =>
            dispatch({
                type: CreateCampaignContextActions.SET_EDITOR_STATE,
                editorState,
                transport: CampaignTypes.EMAIL,
            }),
        [dispatch]
    );

    const setIsNavigatedFromOutside = useCallback(
        (status) =>
            dispatch({
                type: CreateCampaignContextActions.SET_IS_NAVIGATED_FROM_OUTSIDE,
                status,
            }),
        [dispatch]
    );

    const reset = useCallback(
        () => dispatch({ type: CreateCampaignContextActions.RESET }),
        [dispatch]
    );

    const loadMembersCountDataForFiltersOrSegments = useCallback(
        async (filters = [], throwError = false) => {
            try {
                dispatch({
                    type: CreateCampaignContextActions.SET_ATTRIBUTE,
                    key: "isLoadingMemberCount",
                    value: true,
                });

                const filteredData = filters.filter((item) => item.filter);

                if (filteredData.length === 0)
                    throw new Error("No filters found to load members count!");

                const concatenatedFilters = filteredData.map((fD) => fD.filter);
                let filtersToApplyForMemberCount = {};

                if (concatenatedFilters.length === 1) {
                    filtersToApplyForMemberCount = concatenatedFilters[0];
                } else {
                    filtersToApplyForMemberCount = { $or: concatenatedFilters };
                }

                const membersCountResponse = await filterMembers(
                    {
                        limit: 100,
                        skip: 0,
                        regionId,
                        projection: ["_id"],
                    },
                    filtersToApplyForMemberCount
                );

                dispatch({
                    type: CreateCampaignContextActions.SET_ATTRIBUTE,
                    key: "isLoadingMemberCount",
                    value: false,
                });

                return membersCountResponse.data?.total || 0;
            } catch (e) {
                dispatch({
                    type: CreateCampaignContextActions.SET_ATTRIBUTE,
                    key: "isLoadingMemberCount",
                    value: false,
                });
                console.error(e);

                if (throwError) throw e;

                toast.error(
                    <div>
                        Failed to load member count for the applied filters!
                        <br />
                        {e.message
                            ? `Error: ${e.message}`
                            : "Please try again later."}
                    </div>
                );

                return 0;
            }
        },
        [regionId, dispatch]
    );

    const onCreateCampaign = useCallback(async () => {
        try {
            dispatch({
                type: CreateCampaignContextActions.SET_IS_CREATING,
                status: true,
            });
            const segmentFilters = getSegmentFilters({
                appliedMemberFilters: state.appliedMemberFilters,
                filterConfig: state.filterConfig,
            });

            let payload = {
                regionId,
                channel: state.campaignType,
                name: state.campaignName,
                description: state.campaignDescription,
                senderId: state.selectedProvider[0]?.value,
                segmentIds: state.to.map((t) => t?.value),
                segmentFilters,
            };

            payload = validateAndGetLaunchDateTime(payload, {
                selectLaunchDate: state.selectLaunchDate,
                launchDate: state.launchDate,
                hours: state.hours,
                minutes: state.minutes,
                timeOfDay: state.timeOfDay,
            });

            payload = {
                ...payload,
                message: {
                    messageBody:
                        state.campaignType === CampaignTypes.SMS
                            ? exportText(state.smsEditorState)
                            : convertEditorStateToHtml(state.emailEditorState),
                    ...(state.campaignType === CampaignTypes.EMAIL
                        ? { messageSubject: state.subject }
                        : {}),
                },
            };

            await createCampaign(payload);
            toast.success(
                `Successfully created ${
                    state.campaignType === CampaignTypes.EMAIL ? "an" : "a"
                } ${state.campaignType?.toLowerCase()} campaign.`
            );
            const navigateTo = state.campaignType || CampaignTypes.SMS;
            reset();
            history.push(`/campaigns/${navigateTo?.toLowerCase()}`);
        } catch (e) {
            console.error(e);
            toast.error(
                <div>
                    {`Failed to create ${
                        state.campaignType === CampaignTypes.EMAIL ? "an" : "a"
                    } ${state.campaignType?.toLowerCase()} campaign!`}
                    <br />
                    {e.message
                        ? `Error: ${e.message}`
                        : "Please try again later."}
                </div>
            );
        } finally {
            dispatch({
                type: CreateCampaignContextActions.SET_IS_CREATING,
                status: false,
            });
        }
    }, [
        regionId,
        state.campaignName,
        state.campaignDescription,
        state.to,
        state.campaignType,
        state.appliedMemberFilters,
        state.selectedProvider,
        state.selectLaunchDate,
        state.smsEditorState,
        state.emailEditorState,
        state.subject,
        state.filterConfig,
        state.launchDate,
        state.hours,
        state.minutes,
        state.timeOfDay,
        history,
        dispatch,
        reset,
    ]);

    useEffect(() => {
        let providerConfigurations = [];

        if (selectedRegion.providerConfiguration) {
            providerConfigurations = [
                ...getAllConfiguredProviders(
                    selectedRegion.providerConfiguration?.emailProvidersList ||
                        [],
                    CampaignTypes.EMAIL
                ),
                ...getAllConfiguredProviders(
                    selectedRegion.providerConfiguration?.smsProvidersList ||
                        [],
                    CampaignTypes.SMS
                ),
            ];
        } else if (organization?.configuration?.providerConfiguration) {
            providerConfigurations = [
                ...getAllConfiguredProviders(
                    organization.configuration.providerConfiguration
                        ?.emailProvidersList || [],
                    CampaignTypes.EMAIL
                ),
                ...getAllConfiguredProviders(
                    organization.configuration.providerConfiguration
                        ?.smsProvidersList || [],
                    CampaignTypes.SMS
                ),
            ];
        } else providerConfigurations = [];

        dispatch({
            type: CreateCampaignContextActions.SET_ATTRIBUTE,
            key: "configuredProviders",
            value: providerConfigurations
                .filter((pC) => pC?.fromAddress)
                .map((fPC) => ({
                    label: `${fPC.name ? fPC.name + " - " : ""}${
                        fPC.fromAddress
                    }`,
                    value: fPC.fromAddress,
                    providerType: fPC?.providerType || "",
                })),
        });
    }, [
        selectedRegion?.providerConfiguration,
        organization?.configuration?.providerConfiguration,
    ]);

    useEffect(() => {
        onChangeSmsEditor(EditorState.createEmpty());
        onChangeEmailEditor(EditorState.createEmpty());
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    useEffect(() => {
        if (Object.keys(contactAttributes).length > 0) {
            const registeredLocations =
                Object.values(merchantLocations)
                    .reduce((result, item) => {
                        result.push(Object.values(item));
                        return result;
                    }, [])
                    .flat(1) || [];

            const config = buildMemberFilterConfig({
                contactAttributes: {
                    ...contactAttributes,
                    ...fieldsToBeExcludedFromFilters,
                    status: {
                        label: "Status",
                        type: "select",
                        operators: equalAndNotEqualSelectOperators,
                        fieldSettings: Object.values(MemberStatus)
                            .filter((s) => s !== MemberStatus.ARCHIVED)
                            .map((mS) => ({
                                title: toTitleCase(mS),
                                value: mS,
                            })),
                    },
                    type: {
                        label: "Type",
                        type: "select",
                        operators: equalAndNotEqualSelectOperators,
                        fieldSettings: Object.values(MemberTypes)
                            .filter((t) => t !== MemberTypes.CHARITY)
                            .map((mT) => ({
                                title: toTitleCase(mT),
                                value: mT,
                            })),
                    },
                    "tier.tierId": {
                        label: "Tier",
                        type: "select",
                        fieldSettings: tiers.map((tier) => ({
                            title: tier?.name || "~ unknown",
                            value: tier?._id || "~ unknown",
                        })),
                    },
                    "affinityGroup.affinityGroupId": {
                        label: "Affinity Group",
                        type: "select",
                        fieldSettings: affinityGroups.map((affinityGroup) => ({
                            title: affinityGroup?.name || "~ unknown",
                            value: affinityGroup?._id || "~ unknown",
                        })),
                    },
                    merchantLocationId: {
                        label: "Registered Location",
                        type: "select",
                        fieldSettings: registeredLocations.map((location) => ({
                            title: location?.locationName || "~ unknown",
                            value: location?._id || "~ unknown",
                        })),
                    },
                    "notificationPreference.allowPromotionalNotifications": {
                        label: "Allow Promotional Notifications",
                        type: "string",
                    },
                    "notificationPreference.preferredChannel": {
                        label: "Preferred Channel",
                        type: "select",
                        fieldSettings: Object.values(
                            PreferredContactMethods
                        ).map((pCM) => ({
                            title: toTitleCase(pCM),
                            value: pCM,
                        })),
                    },
                    additionalPhoneNumbers: {
                        label: "Additional Phone Numbers",
                        type: "string",
                    },
                    pointsToExpire: {
                        label: "Points To Expire",
                        type: "number",
                    },
                    pointsExpireOn: {
                        label: "Points Expire On",
                        type: "date",
                    },
                    ...identificationFitlers,
                },
                tags: tags || [],
                isLoading: true,
            });

            setMemberFilterConfig(config);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [contactAttributes, tags, affinityGroups, tiers, merchantLocations]);

    const value = useMemo(
        () => ({
            ...state,
            setCampaignType,
            setSelectedProvider,
            setAttribute,
            setToSegments,
            setLaunchDate,
            setLaunchTime,
            setFiltersToApplyForMemberCount,
            onChangeSmsEditor,
            onChangeEmailEditor,
            setMemberFilterConfig,
            setIsNavigatedFromOutside,
            setAppliedMemberFilters,
            setMembersCountForSegmentsOrFilters,
            reset,
            onCreateCampaign,
            loadMembersCountDataForFiltersOrSegments,
        }),
        [
            state,
            onChangeEmailEditor,
            onChangeSmsEditor,
            onCreateCampaign,
            reset,
            setAppliedMemberFilters,
            setAttribute,
            setCampaignType,
            setIsNavigatedFromOutside,
            setLaunchDate,
            setLaunchTime,
            setMemberFilterConfig,
            setToSegments,
            setSelectedProvider,
            setFiltersToApplyForMemberCount,
            setMembersCountForSegmentsOrFilters,
            loadMembersCountDataForFiltersOrSegments,
        ]
    );

    return (
        <CreateCampaignContext.Provider value={value}>
            {props.children}
        </CreateCampaignContext.Provider>
    );
};

CreateCampaignContextProvider.propTypes = { children: PropTypes.any };

export { CreateCampaignContextProvider, CreateCampaignContext };
