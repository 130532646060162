import { UserBoundaryType } from "Data";

const getPermissionPath = (moduleName, permission) => {
    return `${moduleName}.${permission}`;
};

const isUpperOrSameLevelBoundary = (userBoundary, compareWithBoundary) => {
    if (userBoundary === compareWithBoundary) {
        return true;
    }
    if (compareWithBoundary === UserBoundaryType.ROOT) {
        return true;
    }
    switch (userBoundary) {
        case UserBoundaryType.REGIONAL:
            return compareWithBoundary === UserBoundaryType.GLOBAL;

        case UserBoundaryType.MERCHANT:
            return (
                compareWithBoundary === UserBoundaryType.GLOBAL ||
                compareWithBoundary === UserBoundaryType.REGIONAL
            );
        default:
            return false;
    }
};
export { getPermissionPath, isUpperOrSameLevelBoundary };
