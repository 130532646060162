import {
    fetchGetWithTotal,
    fetchPost,
    fetchDelete,
    jsonToQueryParam,
    fetchGet,
    fetchPut,
} from "./CommonServiceUtils";
import Constants from "../Constants";
import {
    AccessPermissionModules,
    UserStatus,
    UserTypes,
    AccessPermissionModuleNames,
} from "Data";
import { getPermissionPath } from "Utils";

const BASE_URL = `${Constants.BASE_URL}identityservice/`;

const getIdentityUsers = (queryObj = {}) => {
    // * "queryObj" values: "regionId", "merchantId", "status"
    return fetchGetWithTotal(
        `${BASE_URL}users?${jsonToQueryParam({
            ...queryObj,
            type: UserTypes.USER,
        })}`,
        true,
        getPermissionPath(
            AccessPermissionModuleNames.USERS,
            AccessPermissionModules[AccessPermissionModuleNames.USERS].actions
                .ListUsers
        )
    );
};

const postUserDataset = ({ projection, datasetKey }) => {
    return fetchPost(
        `${BASE_URL}userdatasets`,
        {
            datasetKey: datasetKey,
            datasetValue: JSON.stringify(projection),
        },
        true,
        getPermissionPath(
            AccessPermissionModuleNames.USER_DATASET,
            AccessPermissionModules[AccessPermissionModuleNames.USER_DATASET]
                .actions.CreateDataset
        )
    );
};

const getUserDataset = ({ datasetKey }) => {
    return fetchGetWithTotal(
        `${BASE_URL}userdatasets?${jsonToQueryParam({
            datasetKey: datasetKey,
        })}`,
        true,
        getPermissionPath(
            AccessPermissionModuleNames.USER_DATASET,
            AccessPermissionModules[AccessPermissionModuleNames.USER_DATASET]
                .actions.ListDatasets
        )
    );
};

const getGroups = (queryObj = {}) => {
    return fetchGet(
        `${BASE_URL}groups?${jsonToQueryParam(queryObj)}`,
        true,
        getPermissionPath(
            AccessPermissionModuleNames.GROUPS,
            AccessPermissionModules[AccessPermissionModuleNames.GROUPS].actions
                .ListGroups
        )
    );
};

const getAllGroups = async (args) => {
    const limit = 500;
    let totalCount = 0,
        groups = [];
    try {
        do {
            const response = await getGroups({
                limit,
                skip: groups.length,
                ...args,
            });
            totalCount = response.total;
            groups = [...groups, ...response.items];
        } while (groups.length < totalCount);
        return groups;
    } catch (e) {
        return Promise.reject(e);
    }
};

const getGroup = (id) => {
    return fetchGet(
        `${BASE_URL}groups/${id}`,
        true,
        getPermissionPath(
            AccessPermissionModuleNames.GROUPS,
            AccessPermissionModules[AccessPermissionModuleNames.GROUPS].actions
                .ListGroups
        )
    );
};

const getAuditLogs = (payload) => {
    return fetchGet(
        `${BASE_URL}auditlogs?${jsonToQueryParam(payload)}`,
        true,
        getPermissionPath(
            AccessPermissionModuleNames.AUDIT_LOG,
            AccessPermissionModules[AccessPermissionModuleNames.AUDIT_LOG]
                .actions.ListAuditLogs
        )
    );
};

const getAuditLogsCount = (payload) => {
    return fetchGet(
        `${BASE_URL}auditlogs/count?${jsonToQueryParam(payload)}`,
        true,
        getPermissionPath(
            AccessPermissionModuleNames.AUDIT_LOG,
            AccessPermissionModules[AccessPermissionModuleNames.AUDIT_LOG]
                .actions.ListAuditLogs
        )
    );
};

const createGroup = ({
    regionId,
    merchantId,
    type,
    name,
    description,
    policies,
}) => {
    return fetchPost(
        `${BASE_URL}groups`,
        {
            regionId,
            merchantId,
            type,
            name,
            description,
            policies,
        },
        true,
        getPermissionPath(
            AccessPermissionModuleNames.GROUPS,
            AccessPermissionModules[AccessPermissionModuleNames.GROUPS].actions
                .CreateGroup
        )
    );
};
const deleteGroup = (id) => {
    return fetchDelete(
        `${BASE_URL}groups/${id}`,
        null,
        null,
        true,
        getPermissionPath(
            AccessPermissionModuleNames.GROUPS,
            AccessPermissionModules[AccessPermissionModuleNames.GROUPS].actions
                .DeleteGroup
        )
    );
};

const updateGroup = (groupId, groupData) => {
    return fetchPut(
        `${BASE_URL}groups/${groupId}`,
        groupData,
        true,
        getPermissionPath(
            AccessPermissionModuleNames.GROUPS,
            AccessPermissionModules[AccessPermissionModuleNames.GROUPS].actions
                .UpdateGroup
        )
    );
};

const getIdentityUser = (userId) => {
    return fetchGet(
        `${BASE_URL}users/${userId}`,
        true,
        getPermissionPath(
            AccessPermissionModuleNames.USERS,
            AccessPermissionModules[AccessPermissionModuleNames.USERS].actions
                .GetUser
        )
    );
};

const updateIdentityUser = (userId, { userData }) => {
    return fetchPut(
        `${BASE_URL}users/${userId}`,
        { userData },
        true,
        getPermissionPath(
            AccessPermissionModuleNames.USERS,
            AccessPermissionModules[AccessPermissionModuleNames.USERS].actions
                .UpdateUser
        )
    );
};

const updateIdentityUserStatus = (userId, status) => {
    return fetchPut(
        `${BASE_URL}users/${userId}`,
        { status },
        true,
        getPermissionPath(
            AccessPermissionModuleNames.USERS,
            AccessPermissionModules[AccessPermissionModuleNames.USERS].actions
                .UpdateUser
        )
    );
};

const resetUserPassword = (resetPayload) => {
    return fetchPost(
        `${BASE_URL}users/resetpassword`,
        resetPayload,
        true,
        getPermissionPath(
            AccessPermissionModuleNames.USERS,
            AccessPermissionModules[AccessPermissionModuleNames.USERS].actions
                .ResetPassword
        )
    );
};

const getUserActivities = ({ userId, limit, skip = 0 }) => {
    return fetchGet(
        `${BASE_URL}useractivities?${jsonToQueryParam({ userId })}`,
        true,
        getPermissionPath(
            AccessPermissionModuleNames.USER_ACTIVITY,
            AccessPermissionModules[AccessPermissionModuleNames.USER_ACTIVITY]
                .actions.ListUserActivities
        )
    );
};

const addUserPermissions = ({ userId, regionId, permissions }) => {
    return fetchPost(
        `${BASE_URL}userpermissions`,
        {
            userId,
            regionId,
            permissions,
        },
        true,
        getPermissionPath(
            AccessPermissionModuleNames.USER_PERMISSION,
            AccessPermissionModules[AccessPermissionModuleNames.USER_PERMISSION]
                .actions.CreateUserPermission
        )
    );
};

const updateUserPermission = ({ id, permissions }) => {
    return fetchPut(
        `${BASE_URL}userpermissions/${id}`,
        {
            permissions: permissions.permissions,
        },
        true,
        getPermissionPath(
            AccessPermissionModuleNames.USER_PERMISSION,
            AccessPermissionModules[AccessPermissionModuleNames.USER_PERMISSION]
                .actions.UpdateUserPermission
        )
    );
};

const deleteUserAccount = (id) => {
    return fetchDelete(
        `${BASE_URL}users/${id}`,
        null,
        null,
        true,
        getPermissionPath(
            AccessPermissionModuleNames.USERS,
            AccessPermissionModules[AccessPermissionModuleNames.USERS].actions
                .DeleteUser
        )
    );
};

const createUser = ({
    boundary,
    regionId,
    merchantId,
    type = UserTypes.USER,
    status = UserStatus.ACTIVE,
    userData,
}) => {
    return fetchPost(
        `${BASE_URL}users`,
        {
            boundary,
            regionId,
            merchantId,
            type,
            status,
            userData,
        },
        true,
        getPermissionPath(
            AccessPermissionModuleNames.USERS,
            AccessPermissionModules[AccessPermissionModuleNames.USERS].actions
                .CreateUser
        )
    );
};

const getUserPermissions = ({ userId }) => {
    return fetchGet(
        `${BASE_URL}userpermissions?${jsonToQueryParam({ userId })}`,
        true,
        getPermissionPath(
            AccessPermissionModuleNames.USER_PERMISSION,
            AccessPermissionModules[AccessPermissionModuleNames.USER_PERMISSION]
                .actions.ListUserPermissions
        )
    );
};

const getModules = () => {
    return fetchGet(
        `${BASE_URL}modules`,
        true,
        getPermissionPath(
            AccessPermissionModuleNames.MODULES,
            AccessPermissionModules[AccessPermissionModuleNames.MODULES].actions
                .ListModules
        )
    );
};

const getIntegrations = (queryObj = {}) =>
    fetchGet(
        `${BASE_URL}users?${jsonToQueryParam({
            type: UserTypes.CLIENT,
            ...queryObj,
        })}`
    );

const getIntegration = (id) => {
    return getIdentityUser(id);
};

const updateIntegrationStatus = (id, status) => {
    return updateIdentityUserStatus(id, status);
};

const deleteIntegration = (id) => {
    return deleteUserAccount(id);
};

const createIntegration = ({
    boundary,
    regionId,
    merchantId,
    type = UserTypes.CLIENT,
    status = UserStatus.ACTIVE,
    clientData,
}) => {
    return fetchPost(`${BASE_URL}users`, {
        boundary,
        regionId,
        merchantId,
        type,
        status,
        clientData,
    });
};

const getProfile = () => {
    return fetchGet(`${BASE_URL}accounts/profile`);
};

// * Retrieve all the clients by applying limit, skip and other args automatically.
const getAllIdentityClients = async (regionId) => {
    const limit = 500;
    let totalCount = 0,
        clients = [];

    try {
        do {
            const clientsResponse = await getIntegrations({
                regionId,
                limit,
                skip: clients.length,
            });
            totalCount = clientsResponse.total;
            clients = [...clients, ...clientsResponse.items];
        } while (clients.length < totalCount);
        return clients;
    } catch (e) {
        return Promise.reject(e);
    }
};

// * Retrieve all the users by applying limit, skip and other args automatically.
const getAllIdentityUsers = async (regionId) => {
    const limit = 500;
    let totalCount = 0,
        users = [];

    try {
        do {
            const usersResponse = await getIdentityUsers({
                regionId,
                limit,
                skip: users.length,
            });
            totalCount = usersResponse.total;
            users = [...users, ...(usersResponse.data?.items || [])];
        } while (users.length < totalCount);
        return users;
    } catch (e) {
        return Promise.reject(e);
    }
};

export {
    getIdentityUsers,
    postUserDataset,
    getUserDataset,
    getGroups,
    deleteGroup,
    getGroup,
    getIdentityUser,
    updateIdentityUser,
    updateIdentityUserStatus,
    getUserActivities,
    addUserPermissions,
    deleteUserAccount,
    resetUserPassword,
    createUser,
    getUserPermissions,
    updateUserPermission,
    getModules,
    createGroup,
    updateGroup,
    getIntegrations,
    createIntegration,
    deleteIntegration,
    getIntegration,
    getProfile,
    getAllGroups,
    getAuditLogs,
    getAllIdentityClients,
    getAllIdentityUsers,
    updateIntegrationStatus,
    getAuditLogsCount
};
