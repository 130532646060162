const GroupStatus = {
    DISABLED: "DISABLED",
    ENABLED: "ENABLED",
};

const GroupStatusOppositePresentTense = {
    [GroupStatus.DISABLED]: "ENABLE",
    [GroupStatus.ENABLED]: "DISABLE",
};

const GroupStatusColorCode = {
    [GroupStatus.DISABLED]: "warning",
    [GroupStatus.ENABLED]: "success",
};

const GroupStatusOppositeColorCode = {
    [GroupStatus.DISABLED]: "success",
    [GroupStatus.ENABLED]: "warning",
};

export {
    GroupStatus,
    GroupStatusOppositePresentTense,
    GroupStatusColorCode,
    GroupStatusOppositeColorCode,
};
