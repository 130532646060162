import {
    OverlayTrigger,
    Tooltip,
} from "@shoutout-labs/shoutout-themes-enterprise";
import Constants from "../Constants";
import { truncateLongString } from "./StringUtils";

const getSmsLength = (message) => {
    message = message.replace(
        /<<(.*?)>>/g,
        Constants.SHORT_URL_DOMAIN + "/xxxxxx"
    );
    return message.length;
};

const getDefaultValuesOfFilters = (
    { filterInput = "", filterKey = "" },
    inputKeys = []
) => {
    switch (filterInput) {
        case "select": {
            return { defaultValue: [] };
        }
        case "date-range": {
            const dateRangeInputMetadata =
                inputKeys.find((iK) => iK.key === "date-range").values || {};
            const dateRangeKeys = dateRangeInputMetadata[filterKey] || {};

            return {
                ...dateRangeKeys,
                defaultFromDate: "",
                defaultToDate: "",
            };
        }
        default: {
            return { defaultValue: "" };
        }
    }
};

const getQueryFilters = (filters = []) =>
    filters.reduce((result, filter) => {
        result = { ...result, ...filter };
        return result;
    }, {});

const getTruncatedStringWithTooltip = ({
    value = "",
    valueMaxLength = 30,
    placement = "bottom",
    customUnknownValue = null,
}) =>
    value ? (
        <>
            {value.length > valueMaxLength ? (
                <OverlayTrigger
                    placement={placement}
                    overlay={<Tooltip id="tooltip-contact">{value}</Tooltip>}
                >
                    <div>{truncateLongString(value, valueMaxLength)}</div>
                </OverlayTrigger>
            ) : (
                value
            )}
        </>
    ) : (
        customUnknownValue || "~ unknown"
    );

export {
    getSmsLength,
    getDefaultValuesOfFilters,
    getQueryFilters,
    getTruncatedStringWithTooltip,
};
