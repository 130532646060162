import numeral from "numeral";

numeral.nullFormat("N/A");

const percentageConverter = (total, amount) => {
    const percentageAmount = ((Number(amount) / Number(total)) * 100).toFixed(
        2
    );
    return percentageAmount;
};

const convertToLocaleString = (number) => {
    if (number && typeof number === "number") {
        return number.toLocaleString();
    } else {
        return 0;
    }
};

const convertNumberFormat = (amount) => {
    return numeral(amount).format("0,0");
};

const roundOffToTwoDecimals = (value) =>
    Math.round((value + Number.EPSILON) * 100) / 100;

const abbreviateNumber = (number, decPlaces) => {
    // * 2 decimal places => 100, 3 => 1000, etc
    decPlaces = Math.pow(10, decPlaces);

    // * Enumerate number abbreviations
    const abbrev = ["K", "M", "B", "T"];

    // * Go through the array backwards, so we do the largest first
    for (let i = abbrev.length - 1; i >= 0; i--) {
        // * Convert array index to "1000", "1000000", etc
        const size = Math.pow(10, (i + 1) * 3);

        // * If the number is bigger or equal do the abbreviation
        if (size <= number) {
            // * Here, we multiply by decPlaces, round, and then divide by decPlaces.
            // * This gives us nice rounding to a particular decimal place.
            number = Math.round((number * decPlaces) / size) / decPlaces;

            // * Handle special case where we round up to the next abbreviation
            if (number === 1000 && i < abbrev.length - 1) {
                number = 1;
                i++;
            }

            // * Add the letter for the abbreviation
            number += abbrev[i];
            break;
        }
    }

    return number;
};

export {
    percentageConverter,
    convertNumberFormat,
    convertToLocaleString,
    roundOffToTwoDecimals,
    abbreviateNumber,
};
