const moment = require("moment");

const PredefinedDatePeriods = {
    "7 Days": {
        toDate: moment().endOf("day").toISOString(),
        fromDate: moment().subtract(6, "day").startOf("day").toISOString(),
        selectedTimePeriod: "prev. 7 days",
    },
    "28 Days": {
        toDate: moment().endOf("day").toISOString(),
        fromDate: moment().subtract(27, "day").startOf("day").toISOString(),
        selectedTimePeriod: "prev. 28 days",
    },
    "90 Days": {
        toDate: moment().endOf("day").toISOString(),
        fromDate: moment().subtract(89, "day").startOf("day").toISOString(),
        selectedTimePeriod: "prev. 90 days",
    },
    "365 Days": {
        toDate: moment().endOf("day").startOf("day").toISOString(),
        fromDate: moment().subtract(364, "day").toISOString(),
        selectedTimePeriod: "prev. 365 days",
    },
};

export { PredefinedDatePeriods };
